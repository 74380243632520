.hl-progress-bar {
  background-color: var(--color-primary);
}

.tooltip-container[data-popper-interactive='false'] {
  pointer-events: none;
}

.tooltip-arrow {
  height: 1rem;
  position: absolute;
  width: 1rem;
  pointer-events: none;
}

.tooltip-arrow::before {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  width: 0;
}

.tooltip-arrow::after {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  position: absolute;
  width: 0;
}

.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow {
  left: 0;
  margin-top: -0.3rem;
  top: 0;
}

.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow::before {
  border-color: transparent transparent #fff transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
  position: absolute;
  top: -1px;
}

.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow::after {
  border-color: transparent transparent #fff transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
}

.tooltip-container[data-popper-placement*='top'] .tooltip-arrow {
  bottom: 0;
  left: 0;
  margin-bottom: -1rem;
}

.tooltip-container[data-popper-placement*='top'] .tooltip-arrow::before {
  border-color: #fff transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
  position: absolute;
  top: 1px;
}

.tooltip-container[data-popper-placement*='top'] .tooltip-arrow::after {
  border-color: #fff transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}

.tooltip-container[data-popper-placement*='right'] .tooltip-arrow {
  left: 0;
  margin-left: -0.7rem;
}

.tooltip-container[data-popper-placement*='right'] .tooltip-arrow::before {
  border-color: transparent #fff transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
}

.tooltip-container[data-popper-placement*='right'] .tooltip-arrow::after {
  border-color: transparent #fff transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
  left: 6px;
  top: 0;
}

.tooltip-container[data-popper-placement*='left'] .tooltip-arrow {
  margin-right: -0.7rem;
  right: 0;
}

.tooltip-container[data-popper-placement*='left'] .tooltip-arrow::before {
  border-color: transparent transparent transparent #fff;
  border-width: 0.5rem 0 0.5rem 0.4em;
}

.tooltip-container[data-popper-placement*='left'] .tooltip-arrow::after {
  border-color: transparent transparent transparent #fff;
  border-width: 0.5rem 0 0.5rem 0.4em;
  left: 3px;
  top: 0;
}

.task-node-card:not(.child):not(.single):before {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--oxford-gray-200) / var(--tw-bg-opacity));
  content: '';
  position: absolute;
  left: 23px;
  width: 1px;
  top: 100%;
  bottom: -46px;
}

.task-node-card.child:before,
.sub-task-button:before {
  border-left-width: 1px;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-bottom-color: rgb(var(--oxford-gray-200) / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-left-color: rgb(var(--oxford-gray-200) / var(--tw-border-opacity));
  content: '';
  position: absolute;
  width: 25px;
  height: 20px;
  left: -25px;
  border-bottom-left-radius: 38%;
}

.sub-task-button:before {
  left: -24px;
  width: 24px;
}

.task-node-card.child:before {
  top: 28px;
}

.sub-task-group:after {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--oxford-gray-200) / var(--tw-bg-opacity));
  content: '';
  position: absolute;
  left: -24px;
  width: 1px;
  top: -8px;
  bottom: -46px;
}

.sub-task-group:last-child:not(.with-sub-task-button):not(
    .no-sub-tasks-button
  ):after {
  content: none;
}

.sub-task-group.no-sub-tasks-button:after {
  content: '';
  bottom: 66px;
}

.sub-task-group.with-sub-task-button:after {
  content: '';
  bottom: 30px;
  top: -8px;
}

.logged-time-date:not(.last)::before {
  border-left-width: 1px;
  --tw-border-opacity: 1;
  border-left-color: rgb(var(--oxford-gray-200) / var(--tw-border-opacity));
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  height: 200%;
  width: 1px;
}

.logged-time-date::after {
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-top-color: rgb(var(--oxford-gray-200) / var(--tw-border-opacity));
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  width: 32px;
  height: 1px;
}

.admin-nav-link-inner:before {
  border-left-width: 1px;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-bottom-color: rgb(var(--oxford-gray-200) / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-left-color: rgb(var(--oxford-gray-200) / var(--tw-border-opacity));
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-65%) translateX(-100%);
  width: 38px;
  height: 70px;
  border-bottom-left-radius: 15px;
}

.admin-nav-link-inner.first:before {
  height: 32px;
  transform: translateY(-22%) translateX(-100%);
}

aside.EmojiPickerReact.epr-main {
  --epr-emoji-size: 24px;
  --epr-text-color: rgb(55 65 81);
  --epr-picker-border-color: rgb(212 218 227);
  border-color: #fff;
  box-shadow: 0px -1px 14px 0px rgba(1, 71, 150, 0.05);
}

aside.EmojiPickerReact.epr-main * {
  font-family: var(--font-family-medium);
}

label.required:after {
  --tw-text-opacity: 1;
  color: rgb(var(--oxford-gray-800) / var(--tw-text-opacity));
  content: '*';
  font-size: 0.75rem;
  position: absolute;
  top: -2px;
}

.contributors:before {
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-bottom-color: rgb(var(--oxford-gray-200) / var(--tw-border-opacity));
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  left: 0;
}

.members-contributor-container:before {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--oxford-gray-200) / var(--tw-bg-opacity));
  content: '';
  position: absolute;
  width: 1px;
  left: 0;
  top: 0;
  bottom: 32px;
}

.goal-node-card:before {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--oxford-gray-200) / var(--tw-bg-opacity));
  content: '';
  width: 25px;
  height: 1px;
  position: absolute;
  left: -25px;
  top: 44px;
}
.goal-counter-node:before {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--oxford-gray-200) / var(--tw-bg-opacity));
  content: '';
  width: 1px;
  height: 52px;
  position: absolute;
  bottom: 0;
  left: 21px;
  top: 95%;
}

.sub-goal-group:before {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--oxford-gray-200) / var(--tw-bg-opacity));
  content: '';
  width: 1px;
  height: calc(100% - 70px);
  position: absolute;
  left: 21px;
  top: 0;
}

.sub-goal:before {
  border-left-width: 1px;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-bottom-color: rgb(var(--oxford-gray-200) / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-left-color: rgb(var(--oxford-gray-200) / var(--tw-border-opacity));
  content: '';
  width: 67px;
  height: 56px;
  position: absolute;
  left: -67px;
  top: -10px;
  border-bottom-left-radius: 20px;
}

.sub-sub-goal:before {
  border-left-width: 1px;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-bottom-color: rgb(var(--oxford-gray-200) / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-left-color: rgb(var(--oxford-gray-200) / var(--tw-border-opacity));
  content: '';
  width: 94px;
  height: 46px;
  position: absolute;
  left: -94px;
  top: 0px;
  border-bottom-left-radius: 20px;
}

.e-grid,
.e-control,
.e-splitter.e-splitter-horizontal .e-pane,
.e-splitter.e-splitter-vertical .e-pane {
  font-family:
    var(--font-family-default),
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji' !important;
}

.sub-goal-group-outer:before {
  border-left-width: 1px;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-bottom-color: rgb(var(--oxford-gray-200) / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-left-color: rgb(var(--oxford-gray-200) / var(--tw-border-opacity));
  content: '';
  width: 1px;
  height: 100%;
  position: absolute;
  left: -67px;
  top: 0px;
}

.ttr-totals-grid .ag-body-vertical-scroll {
  display: none;
}
