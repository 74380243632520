.InlineImageNode__contentEditable {
  @apply resize-none cursor-text block relative p-2 whitespace-pre-wrap break-words;
  width: calc(100% - 20px);
}

.InlineImageNode__placeholder {
  @apply pointer-events-none overflow-hidden absolute text-ellipsis text-oxford-gray-800 left-2 bottom-2 whitespace-nowrap inline-block;
}

/* Image */
.ImageNode__contentEditable {
  @apply resize-none cursor-text block relative outline-none p-2 whitespace-pre-wrap break-words rounded-md;
  width: calc(100% - 20px);
}

.ImageNode__placeholder {
  @apply pointer-events-none overflow-hidden absolute text-ellipsis text-oxford-gray-800 left-2 bottom-2 whitespace-nowrap inline-block;
}

.image-control-wrapper--resizing {
  touch-action: none;
}

/* Editor */
.ContentEditable__root {
  @apply bg-white border-0 block relative outline-none p-2.5 rounded-t-md overflow-y-auto;
  min-height: 72px;
  max-height: 25vh;
}

.ContentEditable__root a {
  @apply text-primary hover:cursor-pointer;
}

.ContentEditable__root ul {
  @apply pl-3;
  list-style-type: unset;
}

.ContentEditable__root ol {
  @apply pl-3 list-decimal;
}

.ContentEditable__placeholder {
  @apply pointer-events-none overflow-hidden absolute text-ellipsis left-2.5 top-2.5 whitespace-nowrap inline-block text-oxford-gray-400;
}

.expanded-root {
  min-height: 172px;
}
.editor-wrapper {
  @apply border rounded-md border-oxford-gray-400;
}

.editor-wrapper:focus-within {
  @apply border-primary;
}

.editor-scroller {
  @apply max-w-full flex relative z-0 resize-y;
}

.editor {
  @apply flex-auto max-w-full relative resize-y -z-10;
}

.typeahead-popover {
  @apply bg-oxford-gray-50 shadow-popup rounded-md fixed;
  transform: translateY(calc(-100% - 25px));
}

.typeahead-popover ul {
  @apply rounded-md;
  max-height: 200px;
}

.typeahead-popover ul::-webkit-scrollbar {
  display: none;
}

.typeahead-popover ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.typeahead-popover ul li {
  @apply cursor-pointer m-0;
  min-width: 180px;
}

.typeahead-popover ul li.selected {
  @apply bg-electric-violet-50;
}

.typeahead-popover li {
  @apply flex items-center p-2 cursor-pointer content-center my-2;
}

.typeahead-popover li:first-child {
  @apply rounded-t-md;
}

.typeahead-popover li:last-child {
  @apply rounded-b-md;
}

.typeahead-popover li:hover {
  background-color: #eee;
}

.mentions-menu {
  width: 250px;
}

.mention {
  @apply bg-electric-pink-100 rounded-sm px-1 font-semibold;
}

.toolbar {
  @apply flex bg-oxford-gray-100 p-1 rounded-b-md sticky top-0 z-10 overflow-y-hidden h-5 items-center border-t border-oxford-gray-400 gap-1 text-oxford-gray-400 text-xs;
}

.toolbar-separator {
  @apply h-3 bg-oxford-gray-300 mx-1;
  width: 1px;
}

button.toolbar-item {
  @apply flex bg-none rounded-md p-2 items-center cursor-pointer justify-between;
}

button.toolbar-item:disabled {
  @apply cursor-not-allowed;
}

button.toolbar-item:disabled .icon,
button.toolbar-item:disabled .text,
button.toolbar-item:disabled i.format,
button.toolbar-item:disabled .chevron-down {
  @apply opacity-20;
}

button.toolbar-item.active {
  @apply bg-oxford-gray-200;
}

.toolbar-item:hover:not([disabled]) {
  @apply bg-oxford-gray-200;
}

.floating-text-format-popup {
  @apply flex bg-white absolute top-0 left-0 z-50 opacity-0 shadow-popup rounded-md transition-opacity align-middle;
  will-change: transform;
}

.comment-item-body a {
  @apply text-primary hover:text-electric-violet-600;
}

.comment-item-body ul,
.comment-item-body ol {
  @apply pl-3;
}

.comment-item-body ul {
  list-style-type: unset;
}

.comment-item-body ol {
  list-style-type: decimal;
}

.comment-item-body h1 {
  @apply text-xl;
}

.comment-item-body blockquote {
  @apply py-2 px-2 rounded-md;
}

.comment-item-body pre {
  @apply p-3 bg-oxford-gray-100 rounded-md;
}

.comment-item-body img {
  @apply hover:cursor-pointer;
  max-width: 300px;
  max-height: 300px;
  border-radius: 8px;
}

.textItalic {
  font-style: italic;
}

.textUnderline {
  text-decoration: underline;
}

.comment-voice-player {
  @apply !flex-1 !w-100 !max-w-full !bg-transparent !h-5 !shadow-none !px-0;
}

.comment-voice-player .rap-total-time {
  @apply !hidden;
}

.comment-voice-player .rap-current-time {
  @apply !text-oxford-gray-600 !font-sans;
}

.comment-voice-player .rap-pp-button {
  @apply !hidden;
}

.comment-voice-player .rap-volume {
  @apply !hidden;
}
.comment-voice-player .rap-pin {
  @apply !bg-primary;
}
.comment-voice-player .rap-progress {
  @apply !bg-primary;
}
