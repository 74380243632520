.e-grid .e-rowcell,
.e-grid .e-groupcaption,
.e-grid .e-indentcell,
.e-grid .e-recordplusexpand,
.e-grid .e-recordpluscollapse,
.e-grid .e-rowdragdropcell,
.e-grid .e-detailrowcollapse,
.e-grid .e-detailrowexpand,
.e-grid .e-detailindentcell,
.e-grid .e-detailcell,
.e-grid .e-emptyrow.e-show-added-row .e-lastrowcell {
  border-color: #e5e7eb;
}

.e-gantt .e-gantt-chart .e-timeline-top-header-cell,
.e-gantt .e-gantt-chart .e-timeline-single-header-cell,
.e-gantt .e-gantt-chart .e-chart-row-border,
.e-gantt .e-gantt-chart .e-line-container-cell {
  border-color: #e5e7eb;
}

.e-gantt .e-gantt-chart .e-timeline-header-table-body .e-weekend-header-cell {
  @apply bg-oxford-gray-50;
}

.e-header-cell-label.e-gantt-top-cell-text {
  @apply text-sm text-oxford-gray-800 font-bold;
}

.e-header-cell-label {
  @apply text-sm text-oxford-gray-800 font-normal;
}

.e-gantt .e-gantt-chart .e-chart-root-container .e-active,
.e-gantt .e-gantt-tree-grid-pane .e-grid .e-content td.e-active {
  @apply bg-electric-violet-50;
}

.e-grid .e-rowcell {
  @apply text-sm;
}

.e-grid .e-focused:not(.e-menu-item):not(.e-editedbatchcell),
.e-gantt .e-grid td.e-focused:not(.e-menu-item) {
  @apply !shadow-none;
}

.e-gantt
  .e-gantt-chart
  .e-chart-root-container
  .e-gantt-parent-taskbar-inner-div {
  @apply outline-none px-2 flex items-center max-w-full;
  height: 34px;
  background-color: initial;
}

.e-gantt
  .e-gantt-chart
  .e-chart-root-container
  .e-gantt-parent-taskbar-inner-div.selected {
  border-width: 2px;
}

.e-gantt
  .e-gantt-chart
  .e-chart-root-container
  .e-gantt-parent-taskbar-inner-div.standby,
.e-gantt
  .e-gantt-chart
  .e-chart-root-container
  .e-gantt-taskbar-inner-div.standby {
  @apply border border-oxford-gray-300;
}

body .e-tooltip-wrap.e-popup {
  @apply border-none outline-none bg-white shadow-popup rounded-md;
}

.e-tooltip-wrap.e-popup .e-tip-content {
  @apply text-oxford-gray-800 text-xs font-sans p-2 overflow-visible;
}

.e-gantt .e-gantt-chart .e-chart-root-container .e-gantt-taskbar-inner-div {
  @apply outline-none px-2 flex justify-between items-center rounded-sm mt-1;
  height: 24px;
  background-color: initial;
}

.e-treecolumn-container {
  text-align: center;
}

.e-gantt
  .e-grid
  .e-icons:not(.e-stop):not(.e-check):not(.e-icon-left):not(
    .e-date-icon
  ):before {
  content: '';
}

.e-row .hover-visible {
  @apply opacity-0;
}

.e-row:hover .hover-visible {
  @apply opacity-100;
}

.e-manualparent-main-container {
  @apply hidden;
}

body .e-tooltip-wrap {
  max-width: 500px;
}

[class*='level1 e-chart-row'] {
  @apply bg-oxford-gray-50;
}

.e-header-cell-label.with-reminder:before {
  @apply text-primary absolute bottom-full pb-1;
  content: '\f0f3';
  font-family: 'Font Awesome 6 Pro';
}

.e-gantt .e-event-markers .e-span-label,
.e-gantt .e-event-markers .e-gantt-right-arrow {
  @apply hidden;
}

.e-split-bar-hover,
.e-split-bar-active {
  @apply !bg-primary;
}

.e-split-bar-hover .e-resize-handler {
  @apply !text-primary;
}

body .e-tooltip-wrap.e-popup {
  @apply !z-40 overflow-visible;
}

.e-gantt .e-gantt-chart .e-holiday {
  @apply !bg-oxford-gray-100;
}
.e-gantt .e-gantt-chart .e-weekend {
  @apply !bg-oxford-gray-50;
}

.e-gantt .e-gantt-chart .e-holiday .e-span {
  @apply !text-oxford-gray-800 font-semibold;
}

.e-emptyrow .e-lastrowcell {
  @apply !pl-4 !pt-3 text-oxford-gray-500 font-semibold;
}
