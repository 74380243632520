/* Default theme variables overrides */
[class*='ag-theme-'] {
  --ag-secondary-foreground-color: inherit;
  --ag-header-foreground-color: inherit;
  --ag-tooltip-background-color: inherit;
  --ag-subheader-background-color: inherit;
  --ag-subheader-toolbar-background-color: inherit;
  --ag-side-button-selected-background-color: inherit;
  --ag-odd-row-background-color: inherit;
  --ag-modal-overlay-background-color: inherit;
  --ag-column-hover-color: inherit;
  --ag-range-selection-background-color: inherit;
  --ag-range-selection-background-color-2: inherit;
  --ag-range-selection-background-color-3: inherit;
  --ag-range-selection-background-color-4: inherit;
  --ag-range-selection-highlight-color: inherit;
  --ag-selected-tab-underline-color: inherit;
  --ag-selected-tab-underline-width: inherit;
  --ag-selected-tab-underline-transition-speed: inherit;
  --ag-range-selection-chart-category-background-color: inherit;
  --ag-range-selection-chart-background-color: inherit;
  --ag-header-cell-moving-background-color: inherit;
  --ag-value-change-value-highlight-background-color: rgb(var(--jade-100));
  --ag-value-change-delta-up-color: inherit;
  --ag-value-change-delta-down-color: inherit;
  --ag-chip-background-color: inherit;
  --ag-borders: inherit;
  --ag-borders-critical: inherit;
  --ag-borders-secondary: inherit;
  --ag-secondary-border-color: inherit;
  --ag-row-border-style: inherit;
  --ag-row-border-color: inherit;
  --ag-row-border-width: inherit;
  --ag-cell-horizontal-border: inherit;
  --ag-borders-input: inherit;
  --ag-input-border-color: inherit;
  --ag-borders-input-invalid: inherit;
  --ag-input-border-color-invalid: inherit;
  --ag-borders-side-button: inherit;
  --ag-border-radius: inherit;
  --ag-row-border-color: inherit;
  --ag-header-column-separator-display: inherit;
  --ag-header-column-separator-height: inherit;
  --ag-header-column-separator-width: inherit;
  --ag-header-column-separator-color: inherit;
  --ag-header-column-resize-handle-display: inherit;
  --ag-header-column-resize-handle-height: inherit;
  --ag-header-column-resize-handle-width: inherit;
  --ag-header-column-resize-handle-color: inherit;
  --ag-invalid-color: inherit;
  --ag-input-disabled-border-color: inherit;
  --ag-input-disabled-background-color: inherit;
  --ag-checkbox-background-color: inherit;
  --ag-checkbox-border-radius: inherit;
  --ag-checkbox-checked-color: inherit;
  --ag-toggle-button-off-border-color: inherit;
  --ag-toggle-button-off-background-color: inherit;
  --ag-toggle-button-on-border-color: inherit;
  --ag-toggle-button-on-background-color: inherit;
  --ag-toggle-button-switch-background-color: inherit;
  --ag-toggle-button-switch-border-color: inherit;
  --ag-toggle-button-border-width: inherit;
  --ag-toggle-button-height: inherit;
  --ag-toggle-button-width: inherit;
  --ag-input-focus-box-shadow: inherit;
  --ag-input-focus-border-color: inherit;
  --ag-minichart-selected-chart-color: inherit;
  --ag-minichart-selected-page-color: inherit;
  --ag-grid-size: inherit;
  --ag-icon-size: inherit;
  --ag-widget-container-horizontal-padding: inherit;
  --ag-widget-container-vertical-padding: inherit;
  --ag-widget-horizontal-spacing: inherit;
  --ag-widget-vertical-spacing: inherit;
  --ag-cell-horizontal-padding: inherit;
  --ag-cell-widget-spacing: inherit;
  --ag-row-height: inherit;
  --ag-header-height: inherit;
  --ag-list-item-height: inherit;
  --ag-column-select-indent-size: inherit;
  --ag-set-filter-indent-size: inherit;
  --ag-row-group-indent-size: inherit;
  --ag-filter-tool-panel-group-indent: inherit;
  --ag-tab-min-width: inherit;
  --ag-menu-min-width: inherit;
  --ag-side-bar-panel-width: inherit;
  --ag-font-family: inherit;
  --ag-font-size: inherit;
  --ag-card-radius: 8px;
  --ag-card-shadow: inherit;
  --ag-material-accent-color: rgb(var(--color-primary));
  --ag-material-primary-color: rgb(var(--color-primary));
  --ag-background-color: rgb(var(--white-gray));
  --ag-header-background-color: rgb(var(--white-gray));
  --ag-foreground-color: rgb(var(--oxford-gray-800));
  --ag-disabled-foreground-color: rgb(var(--oxford-gray-300));
  --ag-popup-shadow: 0 3px 16px #00000029;
  --ag-row-border-color: #e5e7eb;
  --ag-border-color: #e5e7eb;
  --ag-selected-row-background-color: rgb(var(--oxford-gray-100));
  --ag-data-color: rgb(var(--oxford-gray-800));
  --ag-row-hover-color: rgb(var(--oxford-gray-100));
  --ag-header-cell-hover-background-color: rgb(var(--oxford-gray-100));
  --ag-checkbox-unchecked-color: rgb(var(--oxford-gray-200));
  --ag-checkbox-indeterminate-color: rgb(var(--color-primary));
  --ag-range-selection-border-color: transparent;
  --ag-range-selection-border-style: solid;
  --ag-control-panel-background-color: rgb(var(--white-gray));
}

.ag-theme-custom-hl {
  font-family:
    var(--font-family-default),
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  -webkit-font-smoothing: antialiased;
  color: rgba(0, 0, 0, 0.87);
  color: var(--ag-foreground-color, rgba(0, 0, 0, 0.87));
  font-family: var(--font-family-default);
  line-height: normal;
  --ag-list-item-height: 40px;
}

.ag-theme-custom-hl .ag-icon {
  font-family: 'Font Awesome 6 Pro';
  font-size: 18px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ag-theme-custom-hl .ag-icon-aggregation::before {
  content: '\f101';
}
.ag-theme-custom-hl .ag-icon-arrows::before {
  content: '\f102';
}
.ag-theme-custom-hl .ag-icon-asc::before {
  font-weight: 700;
  content: '\f0de';
}
.ag-theme-custom-hl .ag-icon-cancel::before {
  content: '\f104';
}
.ag-theme-custom-hl .ag-icon-chart::before {
  content: '\f105';
}
.ag-theme-custom-hl .ag-icon-color-picker::before {
  content: '\f109';
}
.ag-theme-custom-hl .ag-icon-columns::before {
  content: '\f0db';
}
.ag-theme-custom-hl .ag-icon-contracted::before {
  content: '\f10b';
}
.ag-theme-custom-hl .ag-icon-copy::before {
  content: '\f0c5';
}
.ag-theme-custom-hl .ag-icon-cross::before {
  content: '\f10d';
}
.ag-theme-custom-hl .ag-icon-csv::before {
  content: '\f10e';
}
.ag-theme-custom-hl .ag-icon-desc::before {
  font-weight: 700;
  content: '\f0dd';
}
.ag-theme-custom-hl .ag-icon-excel::before {
  content: '\f110';
}
.ag-theme-custom-hl .ag-icon-expanded::before {
  content: '\f111';
}
.ag-theme-custom-hl .ag-icon-eye-slash::before {
  content: '\f112';
}
.ag-theme-custom-hl .ag-icon-eye::before {
  content: '\f113';
}
.ag-theme-custom-hl .ag-icon-filter::before {
  content: '\f0b0';
}
.ag-theme-custom-hl .ag-icon-first::before {
  content: '\f115';
}
.ag-theme-custom-hl .ag-icon-grip::before {
  content: '\f7a4';
}
.ag-theme-custom-hl .ag-icon-group::before {
  content: '\f117';
}
.ag-theme-custom-hl .ag-icon-last::before {
  content: '\f118';
}
.ag-theme-custom-hl .ag-icon-left::before {
  content: '\f119';
}
.ag-theme-custom-hl .ag-icon-linked::before {
  content: '\f11a';
}
.ag-theme-custom-hl .ag-icon-loading::before {
  content: '\f11b';
}
.ag-theme-custom-hl .ag-icon-maximize::before {
  content: '\f11c';
}
.ag-theme-custom-hl .ag-icon-menu::before {
  content: '\f0c9';
  font-weight: 300;
}
.ag-theme-custom-hl .ag-header-filter-menu .ag-icon-menu::before {
  content: '\f0b0';
  font-weight: 300;
}
.ag-theme-custom-hl .ag-icon-minimize::before {
  content: '\f11e';
}
.ag-theme-custom-hl .ag-icon-next::before {
  content: '\f11f';
}
.ag-theme-custom-hl .ag-icon-none::before {
  font-weight: 300;
  content: '\f0dc';
}
.ag-theme-custom-hl .ag-icon-not-allowed::before {
  content: '\f121';
}
.ag-theme-custom-hl .ag-icon-paste::before {
  content: '\f122';
}
.ag-theme-custom-hl .ag-icon-pin::before {
  content: '\f123';
}
.ag-theme-custom-hl .ag-icon-pivot::before {
  content: '\f124';
}
.ag-theme-custom-hl .ag-icon-previous::before {
  content: '\f125';
}
.ag-theme-custom-hl .ag-icon-right::before {
  content: '\f128';
}
.ag-theme-custom-hl .ag-icon-save::before {
  content: '\f129';
}
.ag-theme-custom-hl .ag-icon-small-down::before {
  font-weight: 700;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  content: '\f0d7';
}
.ag-theme-custom-hl .ag-icon-small-left::before {
  content: '\f12b';
}
.ag-theme-custom-hl .ag-icon-small-right::before {
  content: '\f12c';
}
.ag-theme-custom-hl .ag-icon-small-up::before {
  content: '\f12d';
}
.ag-theme-custom-hl .ag-icon-tick::before {
  content: '\f12e';
}
.ag-theme-custom-hl .ag-icon-tree-closed::before {
  font-weight: 700;
  content: '\f0da';
}
.ag-theme-custom-hl .ag-icon-tree-indeterminate::before {
  content: '\f130';
}
.ag-theme-custom-hl .ag-icon-tree-open::before {
  font-weight: 700;
  content: '\f0d7';
}
.ag-theme-custom-hl .ag-icon-unlinked::before {
  content: '\f132';
}
.ag-theme-custom-hl .ag-icon-row-drag::before {
  content: '\f116';
}
.ag-theme-custom-hl .ag-left-arrow::before {
  content: '\f119';
}
.ag-theme-custom-hl .ag-right-arrow::before {
  content: '\f128';
}
.ag-theme-custom-hl .ag-root-wrapper,
.ag-theme-custom-hl .ag-sticky-top {
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
}
.ag-theme-custom-hl [class^='ag-'],
.ag-theme-custom-hl [class^='ag-']:focus,
.ag-theme-custom-hl [class^='ag-']:after,
.ag-theme-custom-hl [class^='ag-']:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}
.ag-theme-custom-hl [class^='ag-']::-ms-clear {
  display: none;
}
.ag-theme-custom-hl .ag-checkbox .ag-input-wrapper,
.ag-theme-custom-hl .ag-radio-button .ag-input-wrapper {
  overflow: visible;
}
.ag-theme-custom-hl .ag-range-field .ag-input-wrapper {
  height: 100%;
}
.ag-theme-custom-hl .ag-toggle-button {
  -webkit-box-flex: 0;
  flex: none;
  width: unset;
  min-width: unset;
}
.ag-theme-custom-hl .ag-ltr .ag-label-align-right .ag-label {
  margin-left: 8px;
}

.ag-theme-custom-hl input[class^='ag-'] {
  margin: 0;
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
}
.ag-theme-custom-hl textarea[class^='ag-'],
.ag-theme-custom-hl select[class^='ag-'] {
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
}
.ag-theme-custom-hl input[class^='ag-']:not([type]),
.ag-theme-custom-hl input[class^='ag-'][type='text'],
.ag-theme-custom-hl input[class^='ag-'][type='number'],
.ag-theme-custom-hl input[class^='ag-'][type='tel'],
.ag-theme-custom-hl input[class^='ag-'][type='date'],
.ag-theme-custom-hl input[class^='ag-'][type='datetime-local'],
.ag-theme-custom-hl textarea[class^='ag-'] {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
.ag-theme-custom-hl input[class^='ag-']:not([type]):disabled,
.ag-theme-custom-hl input[class^='ag-'][type='text']:disabled,
.ag-theme-custom-hl input[class^='ag-'][type='number']:disabled,
.ag-theme-custom-hl input[class^='ag-'][type='tel']:disabled,
.ag-theme-custom-hl input[class^='ag-'][type='date']:disabled,
.ag-theme-custom-hl input[class^='ag-'][type='datetime-local']:disabled,
.ag-theme-custom-hl textarea[class^='ag-']:disabled {
  color: rgba(0, 0, 0, 0.38);
  color: var(--ag-disabled-foreground-color, rgba(0, 0, 0, 0.38));
  background-color: var(--ag-input-disabled-background-color);
  border-color: var(--ag-input-disabled-border-color);
}
.ag-theme-custom-hl input[class^='ag-']:not([type]):focus,
.ag-theme-custom-hl input[class^='ag-'][type='text']:focus,
.ag-theme-custom-hl input[class^='ag-'][type='number']:focus,
.ag-theme-custom-hl input[class^='ag-'][type='tel']:focus,
.ag-theme-custom-hl input[class^='ag-'][type='date']:focus,
.ag-theme-custom-hl input[class^='ag-'][type='datetime-local']:focus,
.ag-theme-custom-hl textarea[class^='ag-']:focus {
  outline: none;
}
.ag-theme-custom-hl input[class^='ag-']:not([type]):invalid,
.ag-theme-custom-hl input[class^='ag-'][type='text']:invalid,
.ag-theme-custom-hl input[class^='ag-'][type='number']:invalid,
.ag-theme-custom-hl input[class^='ag-'][type='tel']:invalid,
.ag-theme-custom-hl input[class^='ag-'][type='date']:invalid,
.ag-theme-custom-hl input[class^='ag-'][type='datetime-local']:invalid,
.ag-theme-custom-hl textarea[class^='ag-']:invalid {
  border-width: 2px;
  border-style: solid;
  border-color: var(--ag-input-border-color-invalid);
}
.ag-theme-custom-hl input[class^='ag-'][type='number'] {
  -moz-appearance: textfield;
}
.ag-theme-custom-hl
  input[class^='ag-'][type='number']::-webkit-outer-spin-button,
.ag-theme-custom-hl
  input[class^='ag-'][type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ag-theme-custom-hl input[class^='ag-'][type='range'] {
  padding: 0;
}
.ag-theme-custom-hl .ag-drag-handle {
  color: rgba(0, 0, 0, 0.54);
  color: var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54));
}
.ag-theme-custom-hl .ag-list-item,
.ag-theme-custom-hl .ag-virtual-list-item {
  height: 40px;
}
.ag-theme-custom-hl .ag-keyboard-focus .ag-virtual-list-item:focus {
  outline: none;
}
.ag-theme-custom-hl .ag-keyboard-focus .ag-virtual-list-item:focus::after {
  content: '';
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 4px;
  left: 4px;
  display: block;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 1px solid;
  border-color: #3f51b5;
  border-color: var(
    --ag-input-focus-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl .ag-select-list {
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
  overflow-y: auto;
  overflow-x: hidden;
}
.ag-theme-custom-hl .ag-list-item {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ag-theme-custom-hl .ag-list-item.ag-active-item {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--electric-violet-50) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(var(--color-primary) / var(--tw-text-opacity));
}
.ag-theme-custom-hl .ag-select-list-item {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: default;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.ag-theme-custom-hl .ag-select-list-item span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ag-theme-custom-hl .ag-select .ag-picker-field-wrapper {
  height: 40px;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--white) / var(--tw-bg-opacity));
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-bottom-color: rgb(var(--oxford-gray-100) / var(--tw-border-opacity));
  border-radius: 0;
}
.ag-theme-custom-hl .ag-select.ag-disabled .ag-picker-field-wrapper:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ag-theme-custom-hl .ag-select .ag-picker-field-display {
  height: 40px;
  margin: 0px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
}
.ag-theme-custom-hl .ag-select .ag-picker-field-icon {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
}
.ag-theme-custom-hl .ag-set-filter,
.ag-theme-custom-hl .ag-filter {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--white-gray) / var(--tw-bg-opacity));
}
.ag-theme-custom-hl .ag-set-filter .ag-text-field-input-wrapper,
.ag-theme-custom-hl .ag-cell-edit-wrapper .ag-text-field-input-wrapper {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--white) / var(--tw-bg-opacity));
}

.ag-theme-custom-hl .ag-cell-edit-wrapper {
  padding: 8px;
}

.ag-theme-custom-hl .ag-set-filter .ag-text-field-input,
.ag-theme-custom-hl .ag-cell-edit-wrapper .ag-text-field-input,
.ag-theme-custom-hl .ag-cell-edit-wrapper .ag-number-field-input,
.ag-theme-custom-hl .ag-text-area-input {
  --ag-borders-input: 1px solid;
  --ag-input-border-color: transparent;
  border-radius: 0.5rem /* 8px */;
  border-width: 1px !important;
  --tw-border-opacity: 1 !important;
  border-color: rgb(
    var(--oxford-gray-200) / var(--tw-border-opacity)
  ) !important;
}

.ag-theme-custom-hl .ag-set-filter .ag-text-field-input:focus,
.ag-theme-custom-hl .ag-cell-edit-wrapper .ag-text-field-input:focus,
.ag-theme-custom-hl .ag-cell-edit-wrapper .ag-number-field-input:focus,
.ag-theme-custom-hl .ag-text-area-input:focus {
  --ag-borders-input: 1px solid;
  --ag-input-border-color: transparent;
  border-width: 1px !important;
  --tw-border-opacity: 1 !important;
  border-color: rgb(
    var(--electric-violet-500) / var(--tw-border-opacity)
  ) !important;
}

.ag-theme-custom-hl .ag-select.ag-disabled {
  opacity: 0.5;
}
.ag-theme-custom-hl .ag-rich-select {
  background-color: #fafafa;
  background-color: var(--ag-control-panel-background-color, #fafafa);
}
.ag-theme-custom-hl .ag-rich-select-list {
  width: 100%;
  min-width: 200px;
  height: 312px;
}
.ag-theme-custom-hl .ag-rich-select-value {
  padding: 0 8px 0 24px;
  height: 48px;
}
.ag-theme-custom-hl .ag-rich-select-virtual-list-item {
  cursor: default;
  height: 32px;
}
.ag-keyboard-focus
  .ag-theme-custom-hl
  .ag-rich-select-virtual-list-item:focus::after {
  content: none;
}
.ag-theme-custom-hl .ag-rich-select-virtual-list-item:hover {
  background-color: #fafafa;
  background-color: var(--ag-row-hover-color, #fafafa);
}
.ag-theme-custom-hl .ag-rich-select-row {
  padding-left: 24px;
}
.ag-theme-custom-hl .ag-rich-select-row-selected {
  background-color: #eee;
  background-color: var(--ag-selected-row-background-color, #eee);
}
.ag-theme-custom-hl .ag-row-drag,
.ag-theme-custom-hl .ag-selection-checkbox,
.ag-theme-custom-hl .ag-group-expanded,
.ag-theme-custom-hl .ag-group-contracted {
  color: rgba(0, 0, 0, 0.54);
  color: var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54));
}
.ag-theme-custom-hl .ag-ltr .ag-row-drag,
.ag-theme-custom-hl .ag-ltr .ag-selection-checkbox,
.ag-theme-custom-hl .ag-ltr .ag-group-expanded,
.ag-theme-custom-hl .ag-ltr .ag-group-contracted {
  margin-right: 24px;
}

.ag-theme-custom-hl
  .ag-cell-wrapper
  > *:not(.ag-cell-value):not(.ag-group-value) {
  --ag-internal-calculated-line-height: var(--ag-line-height, 46px);
  height: var(--ag-internal-calculated-line-height);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  flex: none;
}
.ag-theme-custom-hl .ag-group-expanded,
.ag-theme-custom-hl .ag-group-contracted {
  cursor: pointer;
}
.ag-theme-custom-hl .ag-group-title-bar-icon {
  cursor: pointer;
  -webkit-box-flex: 0;
  flex: none;
  color: rgba(0, 0, 0, 0.54);
  color: var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54));
}
.ag-theme-custom-hl .ag-ltr .ag-group-child-count {
  margin-left: 2px;
}

.ag-theme-custom-hl .ag-group-title-bar {
  background-color: #eee;
  background-color: var(--ag-subheader-background-color, #eee);
  padding: 8px;
}
.ag-theme-custom-hl .ag-group-toolbar {
  padding: 8px;
}
.ag-theme-custom-hl .ag-disabled-group-title-bar,
.ag-theme-custom-hl .ag-disabled-group-container {
  opacity: 0.5;
}
.ag-theme-custom-hl .group-item {
  margin: 4px 0;
}
.ag-theme-custom-hl .ag-label {
  white-space: nowrap;
}
.ag-theme-custom-hl .ag-ltr .ag-label {
  margin-right: 8px;
}

.ag-theme-custom-hl .ag-label-align-top .ag-label {
  margin-bottom: 4px;
}
.ag-theme-custom-hl .ag-angle-select[disabled] {
  color: var(--ag-disabled-foreground-color, rgba(0, 0, 0, 0.38));
  pointer-events: none;
}
.ag-theme-custom-hl .ag-angle-select[disabled] .ag-angle-select-field {
  opacity: 0.4;
}
.ag-theme-custom-hl .ag-ltr .ag-slider-field,
.ag-theme-custom-hl .ag-ltr .ag-angle-select-field {
  margin-right: 16px;
}

.ag-theme-custom-hl .ag-angle-select-parent-circle {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: solid 1px;
  border-color: #e2e2e2;
  border-color: var(--ag-border-color, #e2e2e2);
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
}
.ag-theme-custom-hl .ag-angle-select-child-circle {
  top: 4px;
  left: 12px;
  width: 6px;
  height: 6px;
  margin-left: -3px;
  margin-top: -4px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.54);
  background-color: var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54));
}
.ag-theme-custom-hl .ag-picker-field-button {
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
  color: rgba(0, 0, 0, 0.54);
  color: var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54));
}
.ag-theme-custom-hl .ag-dialog.ag-color-dialog {
  border-radius: 5px;
}
.ag-theme-custom-hl .ag-color-picker .ag-picker-field-display {
  height: 18px;
}
.ag-theme-custom-hl .ag-color-panel {
  padding: 8px;
}
.ag-theme-custom-hl .ag-spectrum-color {
  background-color: rgb(255, 0, 0);
  border-radius: 2px;
}
.ag-theme-custom-hl .ag-spectrum-tools {
  padding: 10px;
}
.ag-theme-custom-hl .ag-spectrum-sat {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(white),
    to(rgba(204, 154, 129, 0))
  );
  background-image: linear-gradient(to right, white, rgba(204, 154, 129, 0));
}
.ag-theme-custom-hl .ag-spectrum-val {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(black),
    to(rgba(204, 154, 129, 0))
  );
  background-image: linear-gradient(to top, black, rgba(204, 154, 129, 0));
}
.ag-theme-custom-hl .ag-spectrum-dragger {
  border-radius: 12px;
  height: 12px;
  width: 12px;
  border: 1px solid white;
  background: black;
  -webkit-box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.24);
  box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.24);
}
.ag-theme-custom-hl .ag-spectrum-hue-background {
  border-radius: 2px;
}
.ag-theme-custom-hl .ag-spectrum-alpha-background {
  border-radius: 2px;
}
.ag-theme-custom-hl .ag-spectrum-tool {
  margin-bottom: 10px;
  height: 11px;
  border-radius: 2px;
}
.ag-theme-custom-hl .ag-spectrum-slider {
  margin-top: -12px;
  width: 13px;
  height: 13px;
  border-radius: 13px;
  background-color: rgb(248, 248, 248);
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
}
.ag-theme-custom-hl .ag-recent-color {
  margin: 0 3px;
}
.ag-theme-custom-hl .ag-recent-color:first-child {
  margin-left: 0;
}
.ag-theme-custom-hl .ag-recent-color:last-child {
  margin-right: 0;
}
.ag-theme-custom-hl.ag-dnd-ghost {
  background: #fff;
  background: var(--ag-background-color, #fff);
  border-radius: 2px;
  -webkit-box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.54);
  color: var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54));
  height: 56px !important;
  line-height: 56px;
  margin: 0;
  padding: 0 16px;
  -webkit-transform: translateY(16px);
  transform: translateY(16px);
}
.ag-theme-custom-hl .ag-dnd-ghost-icon {
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.87);
  color: var(--ag-foreground-color, rgba(0, 0, 0, 0.87));
}
.ag-dragging-range-handle .ag-theme-custom-hl .ag-dialog,
.ag-dragging-fill-handle .ag-theme-custom-hl .ag-dialog {
  opacity: 0.7;
  pointer-events: none;
}
.ag-theme-custom-hl .ag-dialog {
  border-radius: 0px;
}
.ag-theme-custom-hl .ag-panel {
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
}
.ag-theme-custom-hl .ag-panel-title-bar {
  background-color: #fff;
  background-color: var(--ag-header-background-color, #fff);
  color: rgba(0, 0, 0, 0.54);
  color: var(
    --ag-header-foreground-color,
    var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54))
  );
  height: 56px;
  padding: 8px 24px;
}
.ag-theme-custom-hl .ag-ltr .ag-panel-title-bar-button {
  margin-left: 8px;
}

.ag-theme-custom-hl .ag-tooltip {
  background-color: #fff;
  background-color: var(--ag-header-background-color, #fff);
  color: rgba(0, 0, 0, 0.87);
  color: var(--ag-foreground-color, rgba(0, 0, 0, 0.87));
  padding: 8px;
  border-radius: 2px;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  white-space: normal;
}
.ag-theme-custom-hl .ag-tooltip.ag-tooltip-hiding {
  opacity: 0;
}
.ag-theme-custom-hl .ag-tooltip-custom {
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
.ag-theme-custom-hl .ag-tooltip-custom.ag-tooltip-hiding {
  opacity: 0;
}
.ag-theme-custom-hl .ag-ltr .ag-column-select-indent-1 {
  padding-left: 26px;
}

.ag-theme-custom-hl .ag-ltr .ag-column-select-indent-2 {
  padding-left: 52px;
}

.ag-theme-custom-hl .ag-ltr .ag-column-select-indent-3 {
  padding-left: 78px;
}

.ag-theme-custom-hl .ag-ltr .ag-column-select-indent-4 {
  padding-left: 104px;
}

.ag-theme-custom-hl .ag-ltr .ag-column-select-indent-5 {
  padding-left: 130px;
}

.ag-theme-custom-hl .ag-ltr .ag-column-select-indent-6 {
  padding-left: 156px;
}

.ag-theme-custom-hl .ag-ltr .ag-column-select-indent-7 {
  padding-left: 182px;
}

.ag-theme-custom-hl .ag-ltr .ag-column-select-indent-8 {
  padding-left: 208px;
}

.ag-theme-custom-hl .ag-ltr .ag-column-select-indent-9 {
  padding-left: 234px;
}

.ag-theme-custom-hl .ag-column-select-header-icon {
  cursor: pointer;
}
.ag-theme-custom-hl .ag-keyboard-focus .ag-column-select-header-icon:focus {
  outline: none;
}
.ag-theme-custom-hl
  .ag-keyboard-focus
  .ag-column-select-header-icon:focus::after {
  content: '';
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 0px;
  left: 0px;
  display: block;
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  border: 1px solid;
  border-color: #3f51b5;
  border-color: var(
    --ag-input-focus-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl .ag-ltr .ag-column-group-icons:not(:last-child),
.ag-theme-custom-hl .ag-ltr .ag-column-select-header-icon:not(:last-child),
.ag-theme-custom-hl .ag-ltr .ag-column-select-header-checkbox:not(:last-child),
.ag-theme-custom-hl
  .ag-ltr
  .ag-column-select-header-filter-wrapper:not(:last-child),
.ag-theme-custom-hl .ag-ltr .ag-column-select-checkbox:not(:last-child),
.ag-theme-custom-hl
  .ag-ltr
  .ag-column-select-column-drag-handle:not(:last-child),
.ag-theme-custom-hl
  .ag-ltr
  .ag-column-select-column-group-drag-handle:not(:last-child),
.ag-theme-custom-hl .ag-ltr .ag-column-select-column-label:not(:last-child) {
  margin-right: 16px;
}

.ag-theme-custom-hl
  .ag-keyboard-focus
  .ag-column-select-virtual-list-item:focus {
  outline: none;
}
.ag-theme-custom-hl
  .ag-keyboard-focus
  .ag-column-select-virtual-list-item:focus::after {
  content: '';
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 1px;
  left: 1px;
  display: block;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border: 1px solid;
  border-color: #3f51b5;
  border-color: var(
    --ag-input-focus-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl .ag-column-select-column-group:not(:last-child),
.ag-theme-custom-hl .ag-column-select-column:not(:last-child) {
  margin-bottom: 14px;
}
.ag-theme-custom-hl .ag-column-select-column-readonly,
.ag-theme-custom-hl .ag-column-select-column-group-readonly {
  color: var(--ag-disabled-foreground-color, rgba(0, 0, 0, 0.38));
  pointer-events: none;
}
.ag-theme-custom-hl .ag-ltr .ag-column-select-add-group-indent {
  margin-left: 34px;
}

.ag-theme-custom-hl .ag-column-select-virtual-list-viewport {
  padding: 8px 0px;
}
.ag-theme-custom-hl .ag-column-select-virtual-list-item {
  padding: 0 12px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-1 {
  padding-left: 66px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-1 {
  padding-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-1 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-2 {
  padding-left: 108px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-2 {
  padding-left: 84px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-2 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-3 {
  padding-left: 150px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-3 {
  padding-left: 126px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-3 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-4 {
  padding-left: 192px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-4 {
  padding-left: 168px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-4 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-5 {
  padding-left: 234px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-5 {
  padding-left: 210px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-5 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-6 {
  padding-left: 276px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-6 {
  padding-left: 252px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-6 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-7 {
  padding-left: 318px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-7 {
  padding-left: 294px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-7 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-8 {
  padding-left: 360px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-8 {
  padding-left: 336px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-8 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-9 {
  padding-left: 402px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-9 {
  padding-left: 378px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-9 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-10 {
  padding-left: 444px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-10 {
  padding-left: 420px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-10 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-11 {
  padding-left: 486px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-11 {
  padding-left: 462px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-11 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-12 {
  padding-left: 528px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-12 {
  padding-left: 504px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-12 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-13 {
  padding-left: 570px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-13 {
  padding-left: 546px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-13 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-14 {
  padding-left: 612px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-14 {
  padding-left: 588px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-14 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-15 {
  padding-left: 654px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-15 {
  padding-left: 630px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-15 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-16 {
  padding-left: 696px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-16 {
  padding-left: 672px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-16 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-17 {
  padding-left: 738px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-17 {
  padding-left: 714px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-17 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-18 {
  padding-left: 780px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-18 {
  padding-left: 756px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-18 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-19 {
  padding-left: 822px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-19 {
  padding-left: 798px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-19 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-20 {
  padding-left: 864px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-20 {
  padding-left: 840px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-20 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-21 {
  padding-left: 906px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-21 {
  padding-left: 882px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-21 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-22 {
  padding-left: 948px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-22 {
  padding-left: 924px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-22 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-23 {
  padding-left: 990px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-23 {
  padding-left: 966px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-23 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-24 {
  padding-left: 1032px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-24 {
  padding-left: 1008px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-24 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-25 {
  padding-left: 1074px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-25 {
  padding-left: 1050px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-25 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-26 {
  padding-left: 1116px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-26 {
  padding-left: 1092px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-26 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-27 {
  padding-left: 1158px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-27 {
  padding-left: 1134px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-27 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-28 {
  padding-left: 1200px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-28 {
  padding-left: 1176px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-28 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-29 {
  padding-left: 1242px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-29 {
  padding-left: 1218px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-29 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-30 {
  padding-left: 1284px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-30 {
  padding-left: 1260px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-30 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-31 {
  padding-left: 1326px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-31 {
  padding-left: 1302px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-31 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-32 {
  padding-left: 1368px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-32 {
  padding-left: 1344px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-32 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-33 {
  padding-left: 1410px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-33 {
  padding-left: 1386px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-33 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-34 {
  padding-left: 1452px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-34 {
  padding-left: 1428px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-34 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-35 {
  padding-left: 1494px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-35 {
  padding-left: 1470px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-35 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-36 {
  padding-left: 1536px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-36 {
  padding-left: 1512px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-36 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-37 {
  padding-left: 1578px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-37 {
  padding-left: 1554px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-37 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-38 {
  padding-left: 1620px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-38 {
  padding-left: 1596px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-38 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-39 {
  padding-left: 1662px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-39 {
  padding-left: 1638px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-39 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-40 {
  padding-left: 1704px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-40 {
  padding-left: 1680px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-40 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-41 {
  padding-left: 1746px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-41 {
  padding-left: 1722px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-41 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-42 {
  padding-left: 1788px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-42 {
  padding-left: 1764px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-42 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-43 {
  padding-left: 1830px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-43 {
  padding-left: 1806px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-43 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-44 {
  padding-left: 1872px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-44 {
  padding-left: 1848px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-44 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-45 {
  padding-left: 1914px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-45 {
  padding-left: 1890px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-45 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-46 {
  padding-left: 1956px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-46 {
  padding-left: 1932px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-46 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-47 {
  padding-left: 1998px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-47 {
  padding-left: 1974px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-47 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-48 {
  padding-left: 2040px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-48 {
  padding-left: 2016px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-48 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-49 {
  padding-left: 2082px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-49 {
  padding-left: 2058px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-49 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-50 {
  padding-left: 2124px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-50 {
  padding-left: 2100px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-50 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-51 {
  padding-left: 2166px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-51 {
  padding-left: 2142px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-51 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-52 {
  padding-left: 2208px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-52 {
  padding-left: 2184px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-52 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-53 {
  padding-left: 2250px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-53 {
  padding-left: 2226px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-53 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-54 {
  padding-left: 2292px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-54 {
  padding-left: 2268px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-54 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-55 {
  padding-left: 2334px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-55 {
  padding-left: 2310px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-55 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-56 {
  padding-left: 2376px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-56 {
  padding-left: 2352px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-56 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-57 {
  padding-left: 2418px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-57 {
  padding-left: 2394px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-57 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-58 {
  padding-left: 2460px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-58 {
  padding-left: 2436px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-58 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-59 {
  padding-left: 2502px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-59 {
  padding-left: 2478px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-59 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-60 {
  padding-left: 2544px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-60 {
  padding-left: 2520px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-60 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-61 {
  padding-left: 2586px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-61 {
  padding-left: 2562px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-61 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-62 {
  padding-left: 2628px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-62 {
  padding-left: 2604px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-62 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-63 {
  padding-left: 2670px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-63 {
  padding-left: 2646px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-63 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-64 {
  padding-left: 2712px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-64 {
  padding-left: 2688px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-64 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-65 {
  padding-left: 2754px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-65 {
  padding-left: 2730px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-65 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-66 {
  padding-left: 2796px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-66 {
  padding-left: 2772px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-66 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-67 {
  padding-left: 2838px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-67 {
  padding-left: 2814px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-67 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-68 {
  padding-left: 2880px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-68 {
  padding-left: 2856px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-68 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-69 {
  padding-left: 2922px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-69 {
  padding-left: 2898px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-69 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-70 {
  padding-left: 2964px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-70 {
  padding-left: 2940px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-70 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-71 {
  padding-left: 3006px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-71 {
  padding-left: 2982px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-71 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-72 {
  padding-left: 3048px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-72 {
  padding-left: 3024px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-72 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-73 {
  padding-left: 3090px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-73 {
  padding-left: 3066px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-73 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-74 {
  padding-left: 3132px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-74 {
  padding-left: 3108px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-74 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-75 {
  padding-left: 3174px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-75 {
  padding-left: 3150px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-75 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-76 {
  padding-left: 3216px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-76 {
  padding-left: 3192px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-76 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-77 {
  padding-left: 3258px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-77 {
  padding-left: 3234px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-77 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-78 {
  padding-left: 3300px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-78 {
  padding-left: 3276px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-78 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-79 {
  padding-left: 3342px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-79 {
  padding-left: 3318px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-79 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-80 {
  padding-left: 3384px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-80 {
  padding-left: 3360px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-80 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-81 {
  padding-left: 3426px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-81 {
  padding-left: 3402px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-81 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-82 {
  padding-left: 3468px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-82 {
  padding-left: 3444px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-82 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-83 {
  padding-left: 3510px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-83 {
  padding-left: 3486px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-83 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-84 {
  padding-left: 3552px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-84 {
  padding-left: 3528px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-84 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-85 {
  padding-left: 3594px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-85 {
  padding-left: 3570px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-85 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-86 {
  padding-left: 3636px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-86 {
  padding-left: 3612px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-86 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-87 {
  padding-left: 3678px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-87 {
  padding-left: 3654px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-87 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-88 {
  padding-left: 3720px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-88 {
  padding-left: 3696px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-88 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-89 {
  padding-left: 3762px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-89 {
  padding-left: 3738px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-89 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-90 {
  padding-left: 3804px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-90 {
  padding-left: 3780px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-90 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-91 {
  padding-left: 3846px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-91 {
  padding-left: 3822px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-91 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-92 {
  padding-left: 3888px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-92 {
  padding-left: 3864px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-92 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-93 {
  padding-left: 3930px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-93 {
  padding-left: 3906px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-93 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-94 {
  padding-left: 3972px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-94 {
  padding-left: 3948px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-94 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-95 {
  padding-left: 4014px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-95 {
  padding-left: 3990px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-95 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-96 {
  padding-left: 4056px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-96 {
  padding-left: 4032px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-96 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-97 {
  padding-left: 4098px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-97 {
  padding-left: 4074px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-97 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-98 {
  padding-left: 4140px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-98 {
  padding-left: 4116px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-98 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-99 {
  padding-left: 4182px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-indent-99 {
  padding-left: 4158px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-level-99 .ag-pivot-leaf-group {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-ltr .ag-row-group-leaf-indent {
  margin-left: 42px;
}

.ag-theme-custom-hl .ag-value-change-delta {
  padding-right: 2px;
}
.ag-theme-custom-hl .ag-value-change-delta-up {
  color: #43a047;
  color: var(--ag-value-change-delta-up-color, #43a047);
}
.ag-theme-custom-hl .ag-value-change-delta-down {
  color: #e53935;
  color: var(--ag-value-change-delta-down-color, #e53935);
}
.ag-theme-custom-hl .ag-value-change-value {
  background-color: transparent;
  border-radius: 1px;
  padding-left: 1px;
  padding-right: 1px;
  -webkit-transition: background-color 1s;
  transition: background-color 1s;
}
.ag-theme-custom-hl .ag-value-change-value-highlight {
  background-color: var(
    --ag-value-change-value-highlight-background-color,
    #00acc1
  );
  -webkit-transition: background-color 0.1s;
  transition: background-color 0.1s;
}
.ag-theme-custom-hl .ag-cell-data-changed {
  background-color: var(
    --ag-value-change-value-highlight-background-color,
    #00acc1
  );
}
.ag-theme-custom-hl .ag-cell-data-changed-animation {
  background-color: transparent;
}
.ag-theme-custom-hl .ag-cell-highlight {
  background-color: #fce4ec !important;
  background-color: var(
    --ag-range-selection-highlight-color,
    #fce4ec
  ) !important;
}
.ag-theme-custom-hl .ag-row {
  height: 48px;
  color: rgba(0, 0, 0, 0.87);
  color: var(--ag-data-color, var(--ag-foreground-color, rgba(0, 0, 0, 0.87)));
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: var(
    --ag-row-border-color,
    var(--ag-secondary-border-color, var(--ag-border-color, #e2e2e2))
  );
  border-bottom-style: solid;
}
.ag-row {
  background-color: var(--ag-background-color, #fff);
}
.ag-theme-custom-hl .ag-row-highlight-above::after,
.ag-theme-custom-hl .ag-row-highlight-below::after {
  content: '';
  position: absolute;
  width: calc(100% - 1px);
  height: 1px;
  background-color: #3f51b5;
  background-color: var(
    --ag-range-selection-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
  left: 1px;
}
.ag-theme-custom-hl .ag-row-highlight-above::after {
  top: -1px;
}
.ag-theme-custom-hl .ag-row-highlight-above.ag-row-first::after {
  top: 0;
}
.ag-theme-custom-hl .ag-row-highlight-below::after {
  bottom: 0px;
}
.ag-row-odd {
  background-color: var(--ag-odd-row-background-color);
}
.ag-theme-custom-hl
  .ag-body-horizontal-scroll:not(.ag-scrollbar-invisible)
  .ag-horizontal-left-spacer:not(.ag-scroller-corner) {
  border-right: solid 1px;
  border-right-color: #e2e2e2;
  border-right-color: var(--ag-border-color, #e2e2e2);
}
.ag-theme-custom-hl
  .ag-body-horizontal-scroll:not(.ag-scrollbar-invisible)
  .ag-horizontal-right-spacer:not(.ag-scroller-corner) {
  border-left: solid 1px;
  border-left-color: #e2e2e2;
  border-left-color: var(--ag-border-color, #e2e2e2);
}
.ag-theme-custom-hl .ag-row-selected::before {
  content: '';
  background-color: #eee;
  background-color: var(--ag-selected-row-background-color, #eee);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ag-theme-custom-hl .ag-full-width-row {
  overflow: visible;
}
.ag-theme-custom-hl .ag-row-hover:not(.ag-full-width-row)::before,
.ag-theme-custom-hl .ag-row-hover.ag-full-width-row.ag-row-group::before {
  content: '';
  background-color: #fafafa;
  background-color: var(--ag-row-hover-color, #fafafa);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.ag-theme-custom-hl .ag-row-hover.ag-row-selected::before {
  background-color: #fafafa;
  background-color: var(--ag-row-hover-color, #fafafa);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#eee),
    to(#eee)
  );
  background-image: linear-gradient(#eee, #eee);
}
.ag-theme-custom-hl .ag-row-hover.ag-full-width-row.ag-row-group > * {
  position: relative;
}
.ag-theme-custom-hl .ag-column-hover {
  background-color: #fafafa;
  background-color: var(--ag-column-hover-color, #fafafa);
}
.ag-theme-custom-hl .ag-ltr .ag-right-aligned-cell {
  text-align: right;
}

.ag-theme-custom-hl .ag-ltr .ag-right-aligned-cell .ag-cell-value,
.ag-theme-custom-hl .ag-ltr .ag-right-aligned-cell .ag-group-value {
  margin-left: auto;
}

.ag-cell {
  padding-left: 16px;
  padding-right: 16px;
}

.ag-theme-custom-hl .ag-cell,
.ag-theme-custom-hl .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  --ag-internal-calculated-line-height: var(--ag-line-height, 46px);
  border: 1px solid transparent;
  line-height: var(--ag-internal-calculated-line-height);
  -webkit-font-smoothing: subpixel-antialiased;
}
.ag-theme-custom-hl .ag-row > .ag-cell-wrapper {
  padding-left: 23px;
  padding-right: 23px;
}
.ag-theme-custom-hl .ag-row-dragging {
  cursor: move;
  opacity: 0.5;
}
.ag-theme-custom-hl .ag-cell-inline-editing {
  background: #fff;
  background: var(--ag-background-color, #fff);
  border-radius: 2px;
  -webkit-box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 8px;
  padding: 0;
  height: 48px;
  background-color: #fafafa;
  background-color: var(--ag-control-panel-background-color, #fafafa);
}
.ag-theme-custom-hl .ag-popup-editor {
  border-radius: 0.5rem /* 8px */;
  --tw-shadow: 0 3px 16px #00000029;
  --tw-shadow-colored: 0 3px 16px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background: var(--ag-background-color, #fff);
}
.ag-theme-custom-hl .ag-large-text-input {
  padding: 8px;
  border-radius: 0.5rem /* 8px */;
}
.ag-theme-custom-hl .ag-large-text-input .ag-text-area-input {
  height: 100%;
}

.ag-theme-custom-hl .ag-details-row {
  padding: 40px;
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
}
.ag-theme-custom-hl .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-custom-hl .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-custom-hl .ag-layout-print .ag-center-cols-clipper,
.ag-theme-custom-hl .ag-layout-print .ag-center-cols-container {
  min-height: 50px;
}
.ag-theme-custom-hl .ag-overlay-loading-wrapper {
  background-color: rgba(255, 255, 255, 0.66);
  background-color: var(
    --ag-modal-overlay-background-color,
    rgba(255, 255, 255, 0.66)
  );
}
.ag-theme-custom-hl .ag-overlay-loading-center {
  background: #fff;
  background: var(--ag-background-color, #fff);
  border-radius: 2px;
  -webkit-box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 8px;
}
.ag-theme-custom-hl .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
  padding-top: 30px;
}
.ag-theme-custom-hl .ag-loading {
  display: -webkit-box;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  align-items: center;
}
.ag-theme-custom-hl .ag-ltr .ag-loading {
  padding-left: 24px;
}

.ag-theme-custom-hl .ag-ltr .ag-loading-icon {
  padding-right: 24px;
}

.ag-theme-custom-hl .ag-icon-loading {
  -webkit-animation-name: spin;
  animation-name: spin;
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.ag-theme-custom-hl .ag-floating-top {
  border-bottom: solid 1px;
  border-bottom-color: #e2e2e2;
  border-bottom-color: var(--ag-border-color, #e2e2e2);
}
.ag-theme-custom-hl .ag-floating-bottom {
  border-top: solid 1px;
  border-top-color: #e2e2e2;
  border-top-color: var(--ag-border-color, #e2e2e2);
}
.ag-theme-custom-hl .ag-ltr .ag-cell {
  border-right: solid transparent;
}

.ag-theme-custom-hl .ag-ltr .ag-cell {
  border-right-width: 1px;
}

.ag-theme-custom-hl
  .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(
    .ag-cell-range-single-cell
  ) {
  border-left: solid 1px;
  border-left-color: #e2e2e2;
  border-left-color: var(--ag-border-color, #e2e2e2);
}
.ag-theme-custom-hl
  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(
    .ag-cell-range-single-cell
  ) {
  border-right: solid 1px;
  border-right-color: #e2e2e2;
  border-right-color: var(--ag-border-color, #e2e2e2);
}
.ag-theme-custom-hl .ag-cell-range-selected:not(.ag-cell-focus),
.ag-theme-custom-hl
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-single-cell:not(.ag-cell-inline-editing) {
  background-color: rgba(122, 134, 203, 0.1);
  background-color: var(
    --ag-range-selection-background-color,
    rgba(122, 134, 203, 0.1)
  );
}
.ag-theme-custom-hl
  .ag-cell-range-selected:not(.ag-cell-focus).ag-cell-range-chart,
.ag-theme-custom-hl
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-single-cell:not(.ag-cell-inline-editing).ag-cell-range-chart {
  background-color: rgba(0, 88, 255, 0.1) !important;
  background-color: var(
    --ag-range-selection-chart-background-color,
    rgba(0, 88, 255, 0.1)
  ) !important;
}
.ag-theme-custom-hl
  .ag-cell-range-selected:not(
    .ag-cell-focus
  ).ag-cell-range-chart.ag-cell-range-chart-category,
.ag-theme-custom-hl
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-single-cell:not(
    .ag-cell-inline-editing
  ).ag-cell-range-chart.ag-cell-range-chart-category {
  background-color: rgba(0, 255, 132, 0.1) !important;
  background-color: var(
    --ag-range-selection-chart-category-background-color,
    rgba(0, 255, 132, 0.1)
  ) !important;
}
.ag-theme-custom-hl .ag-cell-range-selected-1:not(.ag-cell-focus),
.ag-theme-custom-hl
  .ag-root:not(.ag-context-menu-open)
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
  background-color: rgba(122, 134, 203, 0.1);
  background-color: var(
    --ag-range-selection-background-color-1,
    var(--ag-range-selection-background-color, rgba(122, 134, 203, 0.1))
  );
}
.ag-theme-custom-hl .ag-cell-range-selected-2:not(.ag-cell-focus),
.ag-theme-custom-hl
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-2 {
  background-color: rgba(122, 134, 203, 0.19);
  background-color: var(
    --ag-range-selection-background-color-2,
    rgba(122, 134, 203, 0.19)
  );
}
.ag-theme-custom-hl .ag-cell-range-selected-3:not(.ag-cell-focus),
.ag-theme-custom-hl
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-3 {
  background-color: rgba(122, 134, 203, 0.271);
  background-color: var(
    --ag-range-selection-background-color-3,
    rgba(122, 134, 203, 0.271)
  );
}
.ag-theme-custom-hl .ag-cell-range-selected-4:not(.ag-cell-focus),
.ag-theme-custom-hl
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-4 {
  background-color: rgba(122, 134, 203, 0.3439);
  background-color: var(
    --ag-range-selection-background-color-4,
    rgba(122, 134, 203, 0.3439)
  );
}
.ag-theme-custom-hl
  .ag-cell.ag-cell-range-selected:not(
    .ag-cell-range-single-cell
  ).ag-cell-range-top {
  border-top-color: #3f51b5;
  border-top-color: var(
    --ag-range-selection-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl
  .ag-cell.ag-cell-range-selected:not(
    .ag-cell-range-single-cell
  ).ag-cell-range-right {
  border-right-color: #3f51b5;
  border-right-color: var(
    --ag-range-selection-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl
  .ag-cell.ag-cell-range-selected:not(
    .ag-cell-range-single-cell
  ).ag-cell-range-bottom {
  border-bottom-color: #3f51b5;
  border-bottom-color: var(
    --ag-range-selection-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl
  .ag-cell.ag-cell-range-selected:not(
    .ag-cell-range-single-cell
  ).ag-cell-range-left {
  border-left-color: #3f51b5;
  border-left-color: var(
    --ag-range-selection-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl
  .ag-ltr
  .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-theme-custom-hl
  .ag-ltr
  .ag-context-menu-open
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-custom-hl
  .ag-ltr
  .ag-full-width-row.ag-row-focus:focus
  .ag-cell-wrapper.ag-row-group,
.ag-theme-custom-hl .ag-ltr .ag-cell-range-single-cell,
.ag-theme-custom-hl .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle {
  outline: none;
}
.ag-theme-custom-hl .ag-cell.ag-selection-fill-top,
.ag-theme-custom-hl .ag-cell.ag-selection-fill-top.ag-cell-range-selected {
  border-top: 1px dashed;
  border-top-color: #3f51b5;
  border-top-color: var(
    --ag-range-selection-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl .ag-ltr .ag-cell.ag-selection-fill-right,
.ag-theme-custom-hl
  .ag-ltr
  .ag-cell.ag-selection-fill-right.ag-cell-range-selected {
  border-right: 1px dashed !important;
  border-right-color: #3f51b5 !important;
  border-right-color: var(
    --ag-range-selection-border-color,
    var(--ag-material-primary-color, #3f51b5)
  ) !important;
}

.ag-theme-custom-hl .ag-cell.ag-selection-fill-bottom,
.ag-theme-custom-hl .ag-cell.ag-selection-fill-bottom.ag-cell-range-selected {
  border-bottom: 1px dashed;
  border-bottom-color: #3f51b5;
  border-bottom-color: var(
    --ag-range-selection-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl .ag-ltr .ag-cell.ag-selection-fill-left,
.ag-theme-custom-hl
  .ag-ltr
  .ag-cell.ag-selection-fill-left.ag-cell-range-selected {
  border-left: 1px dashed !important;
  border-left-color: #3f51b5 !important;
  border-left-color: var(
    --ag-range-selection-border-color,
    var(--ag-material-primary-color, #3f51b5)
  ) !important;
}

.ag-theme-custom-hl .ag-range-handle,
.ag-theme-custom-hl .ag-fill-handle {
  position: absolute;
  width: 6px;
  height: 6px;
  bottom: -1px;
  background-color: #3f51b5;
  background-color: var(
    --ag-range-selection-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl .ag-ltr .ag-range-handle,
.ag-theme-custom-hl .ag-ltr .ag-fill-handle {
  right: -1px;
}

.ag-theme-custom-hl .ag-fill-handle {
  cursor: cell;
}
.ag-theme-custom-hl .ag-range-handle {
  cursor: nwse-resize;
}
.ag-theme-custom-hl .ag-cell-inline-editing {
  border-color: #3f51b5 !important;
  border-color: var(
    --ag-input-focus-border-color,
    var(--ag-material-primary-color, #3f51b5)
  ) !important;
}
.ag-theme-custom-hl .ag-menu {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--white) / var(--tw-bg-opacity));
  border-radius: 0.5rem /* 8px */;
  --tw-shadow: 0 3px 16px #00000029;
  --tw-shadow-colored: 0 3px 16px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ag-theme-custom-hl .ag-menu-list {
  cursor: default;
}
.ag-theme-custom-hl .ag-menu-separator {
  height: 17px;
}
.ag-theme-custom-hl .ag-menu-separator-part::after {
  content: '';
  display: block;
  border-top: solid 1px;
  border-top-color: #e2e2e2;
  border-top-color: var(--ag-border-color, #e2e2e2);
}
.ag-theme-custom-hl .ag-menu-option-active,
.ag-theme-custom-hl .ag-compact-menu-option-active {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--electric-violet-50) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(var(--color-primary) / var(--tw-text-opacity));
}
.ag-theme-custom-hl .ag-menu-option-part,
.ag-theme-custom-hl .ag-compact-menu-option-part {
  line-height: 18px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.ag-theme-custom-hl .ag-menu-option-disabled,
.ag-theme-custom-hl .ag-compact-menu-option-disabled {
  opacity: 0.5;
}
.ag-theme-custom-hl .ag-menu-option-icon,
.ag-theme-custom-hl .ag-compact-menu-option-icon {
  width: 18px;
}
.ag-theme-custom-hl .ag-ltr .ag-menu-option-icon,
.ag-theme-custom-hl .ag-ltr .ag-compact-menu-option-icon {
  padding-left: 16px;
}

.ag-theme-custom-hl .ag-menu-option-text,
.ag-theme-custom-hl .ag-compact-menu-option-text {
  padding-left: 16px;
  padding-right: 16px;
}
.ag-theme-custom-hl .ag-ltr .ag-menu-option-shortcut,
.ag-theme-custom-hl .ag-ltr .ag-compact-menu-option-shortcut {
  padding-right: 8px;
}

.ag-theme-custom-hl .ag-menu-option-popup-pointer,
.ag-theme-custom-hl .ag-compact-menu-option-popup-pointer {
  padding-right: 8px;
}
.ag-theme-custom-hl .ag-tabs {
  min-width: 220px;
}
.ag-theme-custom-hl .ag-tabs-header {
  width: 100%;
  display: none;
}
.ag-theme-custom-hl .ag-tab {
  border-bottom: 2px solid transparent;
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 0;
  flex: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}
.ag-theme-custom-hl .ag-keyboard-focus .ag-tab:focus {
  outline: none;
}
.ag-theme-custom-hl .ag-keyboard-focus .ag-tab:focus::after {
  content: '';
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 4px;
  left: 4px;
  display: block;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 1px solid;
  border-color: #3f51b5;
  border-color: var(
    --ag-input-focus-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl .ag-tab-selected {
  border-bottom-color: #3f51b5;
  border-bottom-color: var(
    --ag-selected-tab-underline-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl .ag-menu-header {
  color: rgba(0, 0, 0, 0.54);
  color: var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54));
}
.ag-theme-custom-hl .ag-filter-separator {
  border-top: solid 1px;
  border-top-color: #e2e2e2;
  border-top-color: var(--ag-border-color, #e2e2e2);
}
.ag-theme-custom-hl .ag-menu:not(.ag-tabs) .ag-filter-select {
  min-width: 155px;
}
.ag-theme-custom-hl .ag-tabs .ag-filter-select {
  min-width: 194px;
}
.ag-theme-custom-hl .ag-filter-select .ag-picker-field-wrapper {
  width: 0;
}
.ag-theme-custom-hl .ag-filter-condition-operator {
  height: 17px;
}
.ag-theme-custom-hl .ag-ltr .ag-filter-condition-operator-or {
  margin-left: 16px;
}

.ag-theme-custom-hl .ag-set-filter-select-all {
  padding-top: 16px;
}
.ag-theme-custom-hl .ag-set-filter-filter {
  margin-top: 16px;
  margin-left: 12px;
  margin-right: 12px;
}
.ag-theme-custom-hl .ag-filter-to {
  margin-top: 14px;
}
.ag-theme-custom-hl .ag-mini-filter {
  padding: 8px;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-bottom-color: rgb(var(--oxford-gray-100) / var(--tw-border-opacity));
}
.ag-theme-custom-hl .ag-set-filter-item {
  padding: 0px 16px;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-bottom-color: rgb(var(--oxford-gray-100) / var(--tw-border-opacity));
}
.ag-theme-custom-hl .ag-ltr .ag-set-filter-item-value {
  margin-left: 12px;
}

.ag-theme-custom-hl .ag-filter-apply-panel {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}
.ag-theme-custom-hl .ag-filter-apply-panel-button {
  line-height: 1.5;
}
.ag-theme-custom-hl .ag-ltr .ag-filter-apply-panel-button {
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  text-transform: capitalize;
}

.ag-theme-custom-hl .ag-simple-filter-body-wrapper {
  margin: 8px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(var(--oxford-gray-100) / var(--tw-border-opacity));
  border-radius: 0.5rem /* 8px */;
}
.ag-theme-custom-hl .ag-filter-no-matches {
  padding: 24px;
}
.ag-theme-custom-hl .ag-multi-filter-menu-item {
  margin: 8px 0;
}
.ag-theme-custom-hl .ag-multi-filter-group-title-bar {
  padding: 16px 8px;
  background-color: transparent;
}
.ag-theme-custom-hl .ag-keyboard-focus .ag-multi-filter-group-title-bar:focus {
  outline: none;
}
.ag-theme-custom-hl
  .ag-keyboard-focus
  .ag-multi-filter-group-title-bar:focus::after {
  content: '';
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 4px;
  left: 4px;
  display: block;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 1px solid;
  border-color: #3f51b5;
  border-color: var(
    --ag-input-focus-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl .ag-side-bar {
  position: relative;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.ag-theme-custom-hl .ag-tool-panel-wrapper {
  width: 200px;
  background-color: #fafafa;
  background-color: var(--ag-control-panel-background-color, #fafafa);
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity)) /* #e5e7eb */;
}

.ag-theme-custom-hl .ag-column-panel .ag-drag-handle {
  display: none;
}

.ag-theme-custom-hl .ag-side-buttons {
  padding-top: 32px;
  /* set the width to 0 to hide the side bar buttons without any side effects */
  width: 0;
  position: relative;
  color: rgba(0, 0, 0, 0.87);
  color: var(--ag-foreground-color, rgba(0, 0, 0, 0.87));
  overflow: hidden;
}
.ag-theme-custom-hl button.ag-side-button-button {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  background: transparent;
  padding: 16px 0 16px 0;
  width: 100%;
  margin: 0;
  min-height: 144px;
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;
  border: none;
}
.ag-theme-custom-hl button.ag-side-button-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ag-theme-custom-hl .ag-keyboard-focus .ag-side-button-button:focus {
  outline: none;
}
.ag-theme-custom-hl .ag-keyboard-focus .ag-side-button-button:focus::after {
  content: '';
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 4px;
  left: 4px;
  display: block;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 1px solid;
  border-color: #3f51b5;
  border-color: var(
    --ag-input-focus-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl .ag-side-button-icon-wrapper {
  margin-bottom: 3px;
}
.ag-theme-custom-hl .ag-ltr .ag-side-bar-left .ag-side-button-button {
  border-right: 2px solid transparent;
}
.ag-theme-custom-hl
  .ag-ltr
  .ag-side-bar-left
  .ag-selected
  .ag-side-button-button {
  border-right-color: #3f51b5;
  border-right-color: var(
    --ag-selected-tab-underline-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}

.ag-theme-custom-hl .ag-ltr .ag-side-bar-right .ag-side-button-button {
  border-left: 2px solid transparent;
}

.ag-theme-custom-hl
  .ag-ltr
  .ag-side-bar-right
  .ag-selected
  .ag-side-button-button {
  border-left-color: #3f51b5;
  border-left-color: var(
    --ag-selected-tab-underline-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}

.ag-theme-custom-hl .ag-pivot-mode-panel {
  min-height: 56px;
  height: 56px;
  display: -webkit-box;
  display: flex;
}
.ag-theme-custom-hl .ag-pivot-mode-select {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.ag-theme-custom-hl .ag-ltr .ag-pivot-mode-select {
  margin-left: 12px;
}

.ag-theme-custom-hl .ag-keyboard-focus .ag-column-select-header:focus {
  outline: none;
}
.ag-theme-custom-hl .ag-keyboard-focus .ag-column-select-header:focus::after {
  content: '';
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 4px;
  left: 4px;
  display: block;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 1px solid;
  border-color: #3f51b5;
  border-color: var(
    --ag-input-focus-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl .ag-column-select-header {
  height: 56px;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 12px;
}
.ag-theme-custom-hl .ag-column-group-icons,
.ag-theme-custom-hl .ag-column-select-header-icon {
  color: rgba(0, 0, 0, 0.54);
  color: var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54));
}
.ag-theme-custom-hl .ag-column-select-list .ag-list-item-hovered::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #3f51b5;
  background-color: var(
    --ag-range-selection-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl .ag-column-select-list .ag-item-highlight-top::after {
  top: 0;
}
.ag-theme-custom-hl .ag-column-select-list .ag-item-highlight-bottom::after {
  bottom: 0;
}
.ag-theme-custom-hl .ag-header {
  background-color: #fff;
  background-color: var(--ag-header-background-color, #fff);
  border-bottom: solid 2px;
  border-bottom-color: #e2e2e2;
  border-bottom-color: var(--ag-border-color, #e2e2e2);
}
.ag-theme-custom-hl .ag-header-row {
  color: rgba(0, 0, 0, 0.54);
  color: var(
    --ag-header-foreground-color,
    var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54))
  );
  height: 56px;
}
.ag-theme-custom-hl .ag-pinned-right-header {
  border-left: solid 1px;
  border-left-color: #e2e2e2;
  border-left-color: var(--ag-border-color, #e2e2e2);
}
.ag-theme-custom-hl .ag-pinned-left-header {
  border-right: solid 1px;
  border-right-color: #e2e2e2;
  border-right-color: var(--ag-border-color, #e2e2e2);
}
.ag-theme-custom-hl
  .ag-ltr
  .ag-header-cell:not(.ag-right-aligned-header)
  .ag-header-label-icon {
  margin-left: 8px;
}

.ag-theme-custom-hl
  .ag-ltr
  .ag-header-cell.ag-right-aligned-header
  .ag-header-label-icon {
  margin-right: 8px;
}

.ag-theme-custom-hl .ag-header-cell:not(.p-0):not(.px-0),
.ag-theme-custom-hl .ag-header-group-cell:not(.p-0):not(.px-0) {
  padding-left: 16px;
  padding-right: 16px;
}
.ag-theme-custom-hl .ag-header-cell.ag-header-cell-moving,
.ag-theme-custom-hl .ag-header-group-cell.ag-header-cell-moving {
  background-color: #f2f2f2;
  background-color: var(
    --ag-header-cell-moving-background-color,
    var(--ag-header-cell-hover-background-color, #f2f2f2)
  );
}
.ag-theme-custom-hl .ag-keyboard-focus .ag-header-cell:focus {
  outline: none;
}
.ag-theme-custom-hl .ag-keyboard-focus .ag-header-cell:focus::after {
  content: '';
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 4px;
  left: 4px;
  display: block;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 1px solid;
  border-color: #3f51b5;
  border-color: var(
    --ag-input-focus-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl .ag-keyboard-focus .ag-header-group-cell:focus {
  outline: none;
}
.ag-theme-custom-hl .ag-keyboard-focus .ag-header-group-cell:focus::after {
  content: '';
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 4px;
  left: 4px;
  display: block;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 1px solid;
  border-color: #3f51b5;
  border-color: var(
    --ag-input-focus-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl .ag-header-icon {
  color: rgba(0, 0, 0, 0.54);
  color: var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54));
}
.ag-theme-custom-hl .ag-header-expand-icon {
  cursor: pointer;
}
.ag-theme-custom-hl .ag-ltr .ag-header-expand-icon {
  padding-left: 4px;
}

.ag-theme-custom-hl .ag-header-row:not(:first-child) .ag-header-cell,
.ag-theme-custom-hl
  .ag-header-row:not(:first-child)
  .ag-header-group-cell.ag-header-group-cell-with-group {
  border-top: solid 1px;
  border-top-color: #e2e2e2;
  border-top-color: var(--ag-border-color, #e2e2e2);
}
.ag-theme-custom-hl
  .ag-header-group-cell:not(.ag-column-resizing)
  + .ag-header-group-cell:not(.ag-header-cell-moving):hover,
.ag-theme-custom-hl
  .ag-header-group-cell:not(.ag-column-resizing)
  + .ag-header-group-cell.ag-column-resizing,
.ag-theme-custom-hl
  .ag-header-cell:not(.ag-column-resizing)
  + .ag-header-cell:not(.ag-header-cell-moving):hover,
.ag-theme-custom-hl
  .ag-header-cell:not(.ag-column-resizing)
  + .ag-header-cell.ag-column-resizing,
.ag-theme-custom-hl
  .ag-header-group-cell:first-of-type:not(.ag-header-cell-moving):hover,
.ag-theme-custom-hl .ag-header-group-cell:first-of-type.ag-column-resizing,
.ag-theme-custom-hl
  .ag-header-cell:first-of-type:not(.ag-header-cell-moving):hover,
.ag-theme-custom-hl .ag-header-cell:first-of-type.ag-column-resizing {
  background-color: #f2f2f2;
  background-color: var(--ag-header-cell-hover-background-color, #f2f2f2);
}
.ag-theme-custom-hl .ag-ltr .ag-header-select-all {
  margin-right: 24px;
}

.ag-theme-custom-hl .ag-ltr .ag-floating-filter-button {
  margin-left: 24px;
}

.ag-theme-custom-hl .ag-floating-filter-button-button {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  height: 18px;
  padding: 0;
  width: 18px;
}
.ag-theme-custom-hl .ag-filter-loading {
  background-color: #fafafa;
  background-color: var(--ag-control-panel-background-color, #fafafa);
  height: 100%;
  padding: 16px 12px;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.ag-theme-custom-hl .ag-paging-panel {
  border-top: 1px solid;
  border-top-color: #e2e2e2;
  border-top-color: var(--ag-border-color, #e2e2e2);
  color: rgba(0, 0, 0, 0.54);
  color: var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54));
  height: 56px;
}
.ag-theme-custom-hl .ag-paging-panel > * {
  margin: 0 24px;
}
.ag-theme-custom-hl .ag-paging-button {
  cursor: pointer;
}
.ag-theme-custom-hl .ag-paging-button.ag-disabled {
  cursor: default;
  color: var(--ag-disabled-foreground-color, rgba(0, 0, 0, 0.38));
}
.ag-theme-custom-hl .ag-keyboard-focus .ag-paging-button:focus {
  outline: none;
}
.ag-theme-custom-hl .ag-keyboard-focus .ag-paging-button:focus::after {
  content: '';
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 0px;
  left: 0px;
  display: block;
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  border: 1px solid;
  border-color: #3f51b5;
  border-color: var(
    --ag-input-focus-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl .ag-paging-button,
.ag-theme-custom-hl .ag-paging-description {
  margin: 0 8px;
}
.ag-theme-custom-hl .ag-status-bar {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--cornflower-blue-50) / var(--tw-bg-opacity));
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 1.5;
}
.ag-theme-custom-hl .ag-status-name-value-value {
  color: var(--ag-foreground-color, rgba(0, 0, 0, 0.87));
}
.ag-theme-custom-hl .ag-status-bar-center {
  text-align: center;
}
.ag-theme-custom-hl .ag-status-name-value {
  margin-left: 8px;
  margin-right: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.ag-theme-custom-hl .ag-column-drop-cell {
  background: #e2e2e2;
  background: var(--ag-chip-background-color, #e2e2e2);
  border-radius: 32px;
  height: 32px;
  padding: 0 4px;
  border: 1px solid transparent;
  cursor: pointer;
}
.ag-theme-custom-hl .ag-keyboard-focus .ag-column-drop-cell:focus {
  outline: none;
}
.ag-theme-custom-hl .ag-keyboard-focus .ag-column-drop-cell:focus::after {
  content: '';
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 2px;
  left: 2px;
  display: block;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border: 1px solid;
  border-color: #3f51b5;
  border-color: var(
    --ag-input-focus-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl .ag-column-drop-cell-text {
  margin: 0 8px;
}
.ag-theme-custom-hl .ag-column-drop-cell-button {
  min-width: 32px;
  margin: 0 4px;
  color: rgba(0, 0, 0, 0.54);
  color: var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54));
}
.ag-theme-custom-hl .ag-column-drop-cell-drag-handle {
  margin-left: 16px;
}
.ag-theme-custom-hl .ag-column-drop-cell-ghost {
  opacity: 0.5;
}
.ag-theme-custom-hl .ag-column-drop-horizontal {
  background-color: #fafafa;
  background-color: var(--ag-control-panel-background-color, #fafafa);
  color: rgba(0, 0, 0, 0.54);
  color: var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54));
  height: 48px;
}
.ag-theme-custom-hl .ag-ltr .ag-column-drop-horizontal {
  padding-left: 24px;
}

.ag-theme-custom-hl .ag-column-drop-horizontal-cell-separator {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.54);
  color: var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54));
}
.ag-theme-custom-hl .ag-column-drop-horizontal-empty-message {
  color: var(--ag-disabled-foreground-color, rgba(0, 0, 0, 0.38));
}
.ag-theme-custom-hl .ag-ltr .ag-column-drop-horizontal-icon {
  margin-right: 24px;
}

.ag-theme-custom-hl .ag-column-drop-vertical-list {
  padding-bottom: 8px;
  padding-right: 8px;
  padding-left: 8px;
}
.ag-theme-custom-hl .ag-column-drop-vertical-cell {
  margin-top: 8px;
}
.ag-theme-custom-hl .ag-column-drop-vertical {
  min-height: 50px;
}
.ag-theme-custom-hl .ag-column-drop-vertical-icon {
  margin-left: 8px;
  margin-right: 8px;
}
.ag-theme-custom-hl .ag-column-drop-vertical-empty-message {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.38);
  color: var(--ag-disabled-foreground-color, rgba(0, 0, 0, 0.38));
  margin-top: 8px;
}
.ag-theme-custom-hl .ag-select-agg-func-popup {
  background: #fff;
  background: var(--ag-background-color, #fff);
  border-radius: 2px;
  -webkit-box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 8px;
  background: #fff;
  background: var(--ag-background-color, #fff);
  height: 140px;
  padding: 0;
}
.ag-theme-custom-hl .ag-select-agg-func-virtual-list-item {
  cursor: default;
  padding-left: 16px;
}
.ag-theme-custom-hl .ag-select-agg-func-virtual-list-item:hover {
  background-color: #eee;
  background-color: var(--ag-selected-row-background-color, #eee);
}
.ag-theme-custom-hl
  .ag-keyboard-focus
  .ag-select-agg-func-virtual-list-item:focus {
  outline: none;
}
.ag-theme-custom-hl
  .ag-keyboard-focus
  .ag-select-agg-func-virtual-list-item:focus::after {
  content: '';
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 1px;
  left: 1px;
  display: block;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border: 1px solid;
  border-color: #3f51b5;
  border-color: var(
    --ag-input-focus-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl .ag-sort-indicator-container {
  display: -webkit-box;
  display: flex;
}
.ag-theme-custom-hl .ag-ltr .ag-sort-indicator-icon {
  padding-left: 8px;
}
.ag-theme-custom-hl .ag-date-time-list-page-title {
  -webkit-box-flex: 1;
  flex-grow: 1;
  text-align: center;
}
.ag-theme-custom-hl .ag-date-time-list-page-column-label {
  text-align: center;
}
.ag-theme-custom-hl .ag-date-time-list-page-entry {
  text-align: center;
}
.ag-theme-custom-hl .ag-checkbox-input-wrapper {
  font-family: 'agGridMaterial';
  font-size: 26px;
  line-height: 26px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 26px;
  height: 26px;
  background-color: var(--ag-checkbox-background-color);
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
  -webkit-box-flex: 0;
  flex: none;
}
.ag-theme-custom-hl .ag-checkbox-input-wrapper input {
  -webkit-appearance: none;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.ag-theme-custom-hl .ag-checkbox-input-wrapper.ag-disabled {
  opacity: 0.5;
}
.ag-theme-custom-hl .ag-checkbox-input-wrapper::after {
  font-family: 'Font Awesome 6 Pro';
  content: '\f0c8';
  color: var(--ag-checkbox-unchecked-color, #333);
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.ag-theme-custom-hl .ag-checkbox-input-wrapper.ag-checked::after {
  content: '\f14a';
  font-weight: 700;
  color: var(
    --ag-checkbox-checked-color,
    var(--ag-material-accent-color, var(--color-primary))
  );
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.ag-theme-custom-hl .ag-checkbox-input-wrapper.ag-indeterminate::after {
  content: '\f146';
  color: var(
    --ag-checkbox-indeterminate-color,
    var(--ag-checkbox-unchecked-color, #333)
  );
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.ag-theme-custom-hl .ag-toggle-button-input-wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 36px;
  height: 18px;
  background-color: #333;
  background-color: var(
    --ag-toggle-button-off-background-color,
    var(--ag-checkbox-unchecked-color, #333)
  );
  border-radius: 9px;
  position: relative;
  -webkit-box-flex: 0;
  flex: none;
  border: 1px solid;
  border-color: #333;
  border-color: var(
    --ag-toggle-button-off-border-color,
    var(--ag-checkbox-unchecked-color, #333)
  );
}
.ag-theme-custom-hl .ag-toggle-button-input-wrapper input {
  opacity: 0;
  height: 100%;
  width: 100%;
}
.ag-theme-custom-hl .ag-toggle-button-input-wrapper:focus-within {
  outline: none;
  -webkit-box-shadow: 0 0 0 5px rgba(32, 33, 36, 0.122);
  box-shadow: 0 0 0 5px rgba(32, 33, 36, 0.122);
}
.ag-theme-custom-hl .ag-toggle-button-input-wrapper.ag-disabled {
  opacity: 0.5;
}
.ag-theme-custom-hl .ag-toggle-button-input-wrapper.ag-checked {
  background-color: var(--color-primary);
  background-color: var(
    --ag-toggle-button-on-background-color,
    var(
      --ag-checkbox-checked-color,
      var(--ag-material-accent-color, var(--color-primary))
    )
  );
  border-color: var(--color-primary);
  border-color: var(
    --ag-toggle-button-on-border-color,
    var(
      --ag-checkbox-checked-color,
      var(--ag-material-accent-color, var(--color-primary))
    )
  );
}
.ag-theme-custom-hl .ag-toggle-button-input-wrapper::before {
  content: ' ';
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 18px;
  width: 18px;
  background-color: #fff;
  background-color: var(
    --ag-toggle-button-switch-background-color,
    var(--ag-background-color, #fff)
  );
  border-radius: 9px;
  -webkit-transition: left 100ms;
  transition: left 100ms;
  border: 1px solid;
  border-color: #333;
  border-color: var(
    --ag-toggle-button-switch-border-color,
    var(
      --ag-toggle-button-off-border-color,
      var(--ag-checkbox-unchecked-color, #333)
    )
  );
}
.ag-theme-custom-hl .ag-toggle-button-input-wrapper.ag-checked::before {
  left: calc(100% - 18px);
  border-color: var(--color-primary);
  border-color: var(
    --ag-toggle-button-on-border-color,
    var(
      --ag-checkbox-checked-color,
      var(--ag-material-accent-color, var(--color-primary))
    )
  );
}
.ag-theme-custom-hl .ag-radio-button-input-wrapper {
  font-family: 'agGridMaterial';
  font-size: 18px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 18px;
  height: 18px;
  background-color: var(--ag-checkbox-background-color);
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
  -webkit-box-flex: 0;
  flex: none;
  border-radius: 18px;
}
.ag-theme-custom-hl .ag-radio-button-input-wrapper input,
.ag-theme-custom-hl .ag-radio-button-input-wrapper input {
  -webkit-appearance: none;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.ag-theme-custom-hl .ag-radio-button-input-wrapper:focus-within,
.ag-theme-custom-hl .ag-radio-button-input-wrapper:active {
  outline: none;
  -webkit-box-shadow: 0 0 0 5px rgba(32, 33, 36, 0.122);
  box-shadow: 0 0 0 5px rgba(32, 33, 36, 0.122);
}
.ag-theme-custom-hl .ag-radio-button-input-wrapper.ag-disabled {
  opacity: 0.5;
}
.ag-theme-custom-hl .ag-radio-button-input-wrapper::after {
  content: '\f126';
  color: #333;
  color: var(--ag-checkbox-unchecked-color, #333);
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.ag-theme-custom-hl .ag-radio-button-input-wrapper.ag-checked::after {
  content: '\f127';
  color: var(--color-primary);
  color: var(
    --ag-checkbox-checked-color,
    var(--ag-material-accent-color, var(--color-primary))
  );
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.ag-theme-custom-hl input[class^='ag-'][type='range'] {
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  background: none;
  overflow: visible;
}
.ag-theme-custom-hl
  input[class^='ag-'][type='range']::-webkit-slider-runnable-track {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 3px;
  background-color: #e2e2e2;
  background-color: var(--ag-border-color, #e2e2e2);
  border-radius: 0px;
  border-radius: 2px;
}
.ag-theme-custom-hl input[class^='ag-'][type='range']::-moz-range-track {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 3px;
  background-color: #e2e2e2;
  background-color: var(--ag-border-color, #e2e2e2);
  border-radius: 0px;
  border-radius: 2px;
}
.ag-theme-custom-hl input[class^='ag-'][type='range']::-ms-track {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 3px;
  background-color: #e2e2e2;
  background-color: var(--ag-border-color, #e2e2e2);
  border-radius: 0px;
  border-radius: 2px;
  color: transparent;
  width: calc(100% - 2px);
}
.ag-theme-custom-hl input[class^='ag-'][type='range']::-webkit-slider-thumb {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
  border: 1px solid;
  border-color: #333;
  border-color: var(--ag-checkbox-unchecked-color, #333);
  border-radius: 18px;
  -webkit-transform: translateY(-7.5px);
  transform: translateY(-7.5px);
}
.ag-theme-custom-hl input[class^='ag-'][type='range']::-ms-thumb {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
  border: 1px solid;
  border-color: #333;
  border-color: var(--ag-checkbox-unchecked-color, #333);
  border-radius: 18px;
}
.ag-theme-custom-hl input[class^='ag-'][type='range']::-moz-ag-range-thumb {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
  border: 1px solid;
  border-color: #333;
  border-color: var(--ag-checkbox-unchecked-color, #333);
  border-radius: 18px;
}
.ag-theme-custom-hl input[class^='ag-'][type='range']:focus {
  outline: none;
}
.ag-theme-custom-hl
  input[class^='ag-'][type='range']:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 5px rgba(32, 33, 36, 0.122);
  box-shadow: 0 0 0 5px rgba(32, 33, 36, 0.122);
  border-color: var(--color-primary);
  border-color: var(
    --ag-checkbox-checked-color,
    var(--ag-material-accent-color, var(--color-primary))
  );
}
.ag-theme-custom-hl input[class^='ag-'][type='range']:focus::-ms-thumb {
  box-shadow: 0 0 0 5px rgba(32, 33, 36, 0.122);
  border-color: var(--color-primary);
  border-color: var(
    --ag-checkbox-checked-color,
    var(--ag-material-accent-color, var(--color-primary))
  );
}
.ag-theme-custom-hl
  input[class^='ag-'][type='range']:focus::-moz-ag-range-thumb {
  box-shadow: 0 0 0 5px rgba(32, 33, 36, 0.122);
  border-color: var(--color-primary);
  border-color: var(
    --ag-checkbox-checked-color,
    var(--ag-material-accent-color, var(--color-primary))
  );
}
.ag-theme-custom-hl
  input[class^='ag-'][type='range']:active::-webkit-slider-runnable-track {
  background-color: #3f51b5;
  background-color: var(
    --ag-input-focus-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl
  input[class^='ag-'][type='range']:active::-moz-ag-range-track {
  background-color: #3f51b5;
  background-color: var(
    --ag-input-focus-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl input[class^='ag-'][type='range']:active::-ms-track {
  background-color: #3f51b5;
  background-color: var(
    --ag-input-focus-border-color,
    var(--ag-material-primary-color, #3f51b5)
  );
}
.ag-theme-custom-hl input[class^='ag-'][type='range']:disabled {
  opacity: 0.5;
}
.ag-theme-custom-hl .ag-filter-toolpanel-header,
.ag-theme-custom-hl .ag-filter-toolpanel-search,
.ag-theme-custom-hl .ag-status-bar,
.ag-theme-custom-hl .ag-header-row,
.ag-theme-custom-hl .ag-panel-title-bar-title,
.ag-theme-custom-hl .ag-side-button-button,
.ag-theme-custom-hl .ag-multi-filter-group-title-bar {
  font-size: 1rem /* 16px */;
  line-height: 1.5rem /* 24px */;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.54);
  color: var(
    --ag-header-foreground-color,
    var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54))
  );
}
.ag-theme-custom-hl .ag-tab {
  height: 36px;
}
.ag-theme-custom-hl .ag-tabs-header,
.ag-theme-custom-hl .ag-column-drop-horizontal {
  background-color: #eee;
  background-color: var(--ag-subheader-background-color, #eee);
}
.ag-theme-custom-hl .ag-tabs-body .ag-menu-list {
  padding-top: 0;
  padding-bottom: 0;
}
.ag-theme-custom-hl .ag-row-last:not(.ag-row-first) .ag-cell-inline-editing {
  bottom: 0;
}
.ag-theme-custom-hl .ag-cell-inline-editing {
  padding: 8px;
  height: 72px;
  border-color: #e2e2e2 !important;
  border-color: var(--ag-border-color, #e2e2e2) !important;
}
.ag-theme-custom-hl .ag-has-focus .ag-cell-inline-editing {
  border-color: #3f51b5 !important;
  border-color: var(
    --ag-input-focus-border-color,
    var(--ag-material-primary-color, #3f51b5)
  ) !important;
}
.ag-theme-custom-hl .ag-side-button-button {
  color: rgba(0, 0, 0, 0.54);
  color: var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54));
}
.ag-theme-custom-hl .ag-column-drop-vertical {
  border-bottom: solid 1px;
  border-bottom-color: #e2e2e2;
  border-bottom-color: var(--ag-border-color, #e2e2e2);
  padding-top: 8px;
}
.ag-theme-custom-hl .ag-column-drop-vertical.ag-last-column-drop {
  border-bottom: none;
}
.ag-theme-custom-hl .ag-column-drop-vertical-cell {
  margin-left: 0;
}
.ag-theme-custom-hl .ag-column-drop-vertical-empty-message {
  font-size: 12px;
  font-weight: 600;
  color: var(--ag-disabled-foreground-color, rgba(0, 0, 0, 0.38));
}
.ag-theme-custom-hl .ag-ltr .ag-column-drop-vertical-empty-message {
  padding-left: 34px;
  padding-right: 8px;
}

.ag-theme-custom-hl .ag-status-bar {
  border: solid 1px;
  border-color: #e2e2e2;
  border-color: var(--ag-border-color, #e2e2e2);
}
.ag-theme-custom-hl .ag-column-select-header {
  border-bottom: solid 2px;
  border-bottom-color: #e2e2e2;
  border-bottom-color: var(--ag-border-color, #e2e2e2);
}
.ag-theme-custom-hl .ag-column-select-header {
  height: 56px;
}
.ag-theme-custom-hl .ag-group-title-bar {
  padding: 6px 8px;
}
.ag-theme-custom-hl .ag-charts-format-sub-level-group-title-bar {
  padding: 4px 8px;
}
.ag-theme-custom-hl .ag-chart-data-section,
.ag-theme-custom-hl .ag-chart-format-section {
  padding-bottom: 4px;
}
.ag-theme-custom-hl .ag-chart-menu-close .ag-icon {
  border: none;
}
.ag-theme-custom-hl .ag-group-toolbar {
  background-color: rgba(238, 238, 238, 0.5);
}
.ag-theme-custom-hl input[class^='ag-']:not([type]),
.ag-theme-custom-hl input[class^='ag-'][type='text'],
.ag-theme-custom-hl input[class^='ag-'][type='number'],
.ag-theme-custom-hl input[class^='ag-'][type='tel'],
.ag-theme-custom-hl input[class^='ag-'][type='date'],
.ag-theme-custom-hl input[class^='ag-'][type='datetime-local'],
.ag-theme-custom-hl textarea[class^='ag-'] {
  --tw-text-opacity: 1;
  color: rgb(var(--oxford-gray-800) / var(--tw-text-opacity));
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  height: 40px;
}
.ag-theme-custom-hl input[class^='ag-']:focus:not([type]),
.ag-theme-custom-hl input[class^='ag-'][type='text']:focus,
.ag-theme-custom-hl input[class^='ag-'][type='number']:focus,
.ag-theme-custom-hl input[class^='ag-'][type='tel']:focus,
.ag-theme-custom-hl input[class^='ag-'][type='date']:focus,
.ag-theme-custom-hl input[class^='ag-'][type='datetime-local']:focus,
.ag-theme-custom-hl textarea[class^='ag-']:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ag-theme-custom-hl input[class^='ag-']:not([type])::-webkit-input-placeholder,
.ag-theme-custom-hl input[class^='ag-'][type='text']::-webkit-input-placeholder,
.ag-theme-custom-hl
  input[class^='ag-'][type='number']::-webkit-input-placeholder,
.ag-theme-custom-hl input[class^='ag-'][type='tel']::-webkit-input-placeholder,
.ag-theme-custom-hl input[class^='ag-'][type='date']::-webkit-input-placeholder,
.ag-theme-custom-hl
  input[class^='ag-'][type='datetime-local']::-webkit-input-placeholder,
.ag-theme-custom-hl textarea[class^='ag-']::-webkit-input-placeholder {
  color: var(--ag-disabled-foreground-color, rgba(0, 0, 0, 0.38));
}
.ag-theme-custom-hl input[class^='ag-']:not([type])::-moz-placeholder,
.ag-theme-custom-hl input[class^='ag-'][type='text']::-moz-placeholder,
.ag-theme-custom-hl input[class^='ag-'][type='number']::-moz-placeholder,
.ag-theme-custom-hl input[class^='ag-'][type='tel']::-moz-placeholder,
.ag-theme-custom-hl input[class^='ag-'][type='date']::-moz-placeholder,
.ag-theme-custom-hl
  input[class^='ag-'][type='datetime-local']::-moz-placeholder,
.ag-theme-custom-hl textarea[class^='ag-']::-moz-placeholder {
  color: var(--ag-disabled-foreground-color, rgba(0, 0, 0, 0.38));
}
.ag-theme-custom-hl input[class^='ag-']:not([type])::placeholder,
.ag-theme-custom-hl input[class^='ag-'][type='text']::placeholder,
.ag-theme-custom-hl input[class^='ag-'][type='number']::placeholder,
.ag-theme-custom-hl input[class^='ag-'][type='tel']::placeholder,
.ag-theme-custom-hl input[class^='ag-'][type='date']::placeholder,
.ag-theme-custom-hl input[class^='ag-'][type='datetime-local']::placeholder,
.ag-theme-custom-hl textarea[class^='ag-']::placeholder {
  color: var(--ag-disabled-foreground-color, rgba(0, 0, 0, 0.38));
}
.ag-theme-custom-hl input[class^='ag-']:disabled:not([type]),
.ag-theme-custom-hl input[class^='ag-'][type='text']:disabled,
.ag-theme-custom-hl input[class^='ag-'][type='number']:disabled,
.ag-theme-custom-hl input[class^='ag-'][type='tel']:disabled,
.ag-theme-custom-hl input[class^='ag-'][type='date']:disabled,
.ag-theme-custom-hl input[class^='ag-'][type='datetime-local']:disabled,
.ag-theme-custom-hl textarea[class^='ag-']:disabled {
  border-bottom: 1px solid;
  border-bottom-color: #e2e2e2;
  border-bottom-color: var(--ag-border-color, #e2e2e2);
}
.ag-theme-custom-hl input[class^='ag-']:invalid:not([type]),
.ag-theme-custom-hl input[class^='ag-'][type='text']:invalid,
.ag-theme-custom-hl input[class^='ag-'][type='number']:invalid,
.ag-theme-custom-hl input[class^='ag-'][type='tel']:invalid,
.ag-theme-custom-hl input[class^='ag-'][type='date']:invalid,
.ag-theme-custom-hl input[class^='ag-'][type='datetime-local']:invalid,
.ag-theme-custom-hl textarea[class^='ag-']:invalid {
  border-width: 0;
  border-bottom: 1px solid;
  border-bottom-color: #e02525;
  border-bottom-color: var(--ag-invalid-color, #e02525);
  color: #e02525;
  color: var(--ag-invalid-color, #e02525);
}
.ag-theme-custom-hl .ag-standard-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  color: var(--ag-material-primary-color, #3f51b5);
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}
.ag-theme-custom-hl .ag-standard-button:disabled {
  color: var(--ag-disabled-foreground-color, rgba(0, 0, 0, 0.38));
  background-color: var(--ag-input-disabled-background-color);
  border-color: var(--ag-input-disabled-border-color);
}
.ag-theme-custom-hl .ag-dnd-ghost {
  font-size: 12px;
  font-weight: 600;
}
.ag-theme-custom-hl .ag-filter-toolpanel-header {
  height: 32px;
}
.ag-theme-custom-hl .ag-filter-toolpanel-group-level-0-header {
  height: 56px;
}

.ag-theme-custom-hl .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-custom-hl .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-custom-hl .ag-layout-print .ag-center-cols-clipper,
.ag-theme-custom-hl .ag-layout-print .ag-center-cols-container {
  min-height: 150px;
}
.ag-theme-custom-hl .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
  padding-top: 60px;
}

.ag-theme-custom-hl .ag-header-filter-menu:hover {
  cursor: pointer;
}

.ag-theme-custom-hl
  .ag-header-cell:not(.ag-header-active)
  .ag-header-filter-menu,
.ag-theme-custom-hl .ag-header-cell:not(.ag-header-active) .ag-header-asc-desc {
  visibility: hidden;
}

.ag-theme-custom-hl .ag-header-cell:not(.ag-header-active) .ag-header-asc-desc {
  position: absolute;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--oxford-gray-50) / var(--tw-bg-opacity));
  right: 16px;
}

.ag-theme-custom-hl
  .ag-header-cell.ag-header-cell-filtered
  .ag-header-filter-menu {
  visibility: visible;
}
.ag-theme-custom-hl
  .ag-header-cell.ag-header-cell-filtered
  .ag-header-filter-menu
  .ag-icon-menu:before {
  --tw-text-opacity: 1;
  color: rgb(var(--color-primary) / var(--tw-text-opacity));
  font-weight: 700;
}
.ag-theme-custom-hl
  .ag-header-cell:not(.ag-header-active):not(.ag-header-cell-filtered)
  .ag-header-filter-menu,
.ag-theme-custom-hl .ag-header-cell .ag-header-filtered {
  position: absolute;
  visibility: hidden;
}

.ag-theme-custom-hl .ag-cell-label-container {
  flex-direction: row;
}

.ag-theme-custom-hl
  .ag-header-active.ag-header-cell-filtered
  .ag-header-filter-menu {
  font-weight: 700;
}

.ag-theme-custom-hl .ag-header-cell-label {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
  font-weight: 600;
  margin-right: 8px;
}

.ag-theme-custom-hl .ag-header-cell-label .ag-header-cell-text {
  flex: 1;
}

.ag-theme-custom-hl .ag-root-wrapper {
  border-radius: 1rem;
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  overflow: visible;
}

.ag-theme-custom-hl .ag-root {
  border-radius: 1rem;
}

.ag-grate .ag-theme-custom-hl .ag-ltr .ag-header-group-cell,
.ag-grate .ag-theme-custom-hl .ag-ltr .ag-cell {
  border-right: solid 1px var(--ag-row-border-color);
}

.ag-grate .ag-theme-custom-hl .ag-ltr .ag-header-cell {
  border-right: solid 1px var(--ag-row-border-color);
}

.ag-grate:not(.ag-inline) .ag-theme-custom-hl .ag-ltr .ag-header-row {
  border-left: solid 1px var(--ag-row-border-color);
  border-right: solid 1px var(--ag-row-border-color);
}

.ag-theme-custom-hl .ag-row-hover:not(.ag-row-level-1) .hover-visible {
  opacity: 1;
}
.ag-theme-custom-hl .dynamic-reminder-cell {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--electric-violet-50) / var(--tw-bg-opacity));
}

.ag-theme-custom-hl .ag-row-hover .notification-state {
  visibility: visible;
}

.ag-theme-custom-hl .ag-filter-filter input[class^='ag-'][type='date'] {
  border-bottom-width: 0px;
}

.ag-theme-custom-hl .ag-cell.editable-field:hover {
  cursor: pointer;
}

.ag-theme-custom-hl .ag-cell.editable-field:hover:before {
  content: ' ';
  position: absolute;
  left: 8px;
  right: 8px;
  top: 8px;
  bottom: 8px;
  border-radius: 0.5rem /* 8px */;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(var(--oxford-gray-200) / var(--tw-border-opacity));
  pointer-events: none;
}

.ag-theme-custom-hl .ag-cell.editable-field.editable-field-dropdown:hover:after,
.ag-theme-custom-hl .ag-cell.editable-field.editable-field-date:hover:after {
  font-family: 'Font Awesome 6 Pro';
  position: absolute;
  right: 8px;
  top: 50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  height: 24px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 700;
}
.ag-theme-custom-hl
  .ag-cell.editable-field.editable-field-dropdown:hover:after {
  content: '\f0d7';
  --tw-text-opacity: 1;
  color: rgb(var(--oxford-gray-800) / var(--tw-text-opacity));
}

.ag-theme-custom-hl .ag-cell.editable-field.editable-field-date:hover:after {
  content: '\f133';
}

.ag-theme-custom-hl .ag-header-row {
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
}

/* OVERRIDES */
.ag-inline .ag-root-wrapper {
  border-radius: 0;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ag-inline .ag-root {
  border-radius: 0;
}

.ag-no-bg .ag-root-wrapper,
.ag-no-bg .ag-theme-custom-hl .ag-header,
.ag-no-bg .ag-row {
  background-color: #00000000;
}

.ag-inline.ag-grate {
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-top-color: rgb(229 231 235 / var(--tw-border-opacity)) /* #e5e7eb */;
}

.ag-grate:not(.ag-inline) {
  border-radius: 1rem;
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-top-color: rgb(229 231 235 / var(--tw-border-opacity)) /* #e5e7eb */;
}

.ag-no-border .ag-row,
.ag-no-border .ag-header {
  border-style: none;
}

.child-row-view .ag-row,
.child-row-view .ag-header-row {
  border-left-width: 0px;
  border-right-width: 0px;
}

.child-row-view .ag-root-wrapper {
  border: 1px solid #e5e7eb;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ag-row-level-1 .ag-root-wrapper {
  overflow: visible;
}

.notification-list .ag-set-filter-list {
  min-width: 380px;
}
